import { Fade, Paper, Stack, Box, Typography } from "@mui/material";
import { useState } from "react";
import DefaultButton from "./DefaultButton";

export default function CookiesBanner() {
	const [bannerOpen, setBannerOpen] = useState(true);

	const closeBanner = () => {
		setBannerOpen(false);
	};

	const learnMore = () => {
		window.open("https://www.iubenda.com/privacy-policy/75279225", "_blank");
	}

	return (
		<Fade appear={false} in={bannerOpen}>
			<Paper
				role="dialog"
				aria-modal="false"
				aria-label="Cookie banner"
				square
				variant="outlined"
				tabIndex={-1}
				sx={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					m: 0,
					p: 2,
					borderWidth: 0,
					borderTopWidth: 1,
				}}
			>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent="space-between"
					gap={2}
				>
					<Box
						sx={{
							flexShrink: 1,
							alignSelf: { xs: 'flex-start', sm: 'center' },
						}}
					>
						<Typography fontWeight="bold">This website uses cookies</Typography>
						<Typography variant="body2">
							sublocks.com relies on cookies to improve your experience.
						</Typography>
					</Box>
					<Stack
						gap={2}
						direction="row"
						sx={{
							flexShrink: 0,
							alignSelf: { xs: 'flex-end', sm: 'center' },
						}}
					>
						<DefaultButton
							onClick={learnMore}
							sx={{
								px: { xs: 2, sm: 3 },
								py: { xs: 0.5, sm: 1 },
								fontSize: { xs: 13, sm: 14 },
								color: "#fff",
								backgroundColor: "transparent",
								'&:hover': {
									backgroundColor: '#f2f2f210'
								},
							}}>
							Learn more
						</DefaultButton>
						<DefaultButton
							onClick={closeBanner}
							sx={{
								px: { xs: 2, sm: 3 },
								py: { xs: 0.5, sm: 1 },
								fontSize: { xs: 13, sm: 14 },
								color: "#fff",
								backgroundColor: "transparent",
								'&:hover': {
									backgroundColor: '#f2f2f210'
								},
							}}>
							Reject all
						</DefaultButton>
						<DefaultButton
							onClick={closeBanner}
							sx={{
								px: { xs: 2, sm: 3 },
								py: { xs: 0.5, sm: 1 },
								fontSize: { xs: 13, sm: 14 },
							}}>
							Allow all
						</DefaultButton>
					</Stack>
				</Stack>
			</Paper>
		</Fade>
	);
}