import { Button, IconButton, Stack } from "@mui/material";
import Logo from "../Logo";
import { useNavigate, useLocation } from "react-router-dom";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useState } from "react";
import ServicesSection from "../../models/ServicesSection";
import ServicesMenu from "./ServicesMenu";
import ProductsSection from "../../models/ProductsSection";
import ProductsMenu from "./ProductsMenu";
import MobileNavbar from "./MobileNavbar";
import useLocalization from "../../hooks/useLocalization";

export default function Navbar({ onScrollToGetInTouch, ...props }: { onScrollToGetInTouch?: () => void }) {
	const navigate = useNavigate();
	const { search } = useLocation();
	const localization = useLocalization();

	const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
	const [onProductsButton, setOnProductsButton] = useState<boolean>(false);
	const [onProductsMenu, setOnProductsMenu] = useState<boolean>(false);
	const [onServicesButton, setOnServicesButton] = useState<boolean>(false);
	const [onServicesMenu, setOnServicesMenu] = useState<boolean>(false);
	const [servicesMenuAnchorElement, setServicesMenuAnchorElement] = useState<HTMLElement | null>(null);
	const [productsMenuAnchorElement, setProductsMenuAnchorElement] = useState<HTMLElement | null>(null);

	function navigateToHome() {
		navigate({
			pathname: `/`,
			search: search,
		});
	}

	function navigateToProducts() {
		navigate({
			pathname: `/products`,
			search: search,
		});
	}

	function navigateToServices() {
		navigate({
			pathname: `/services`,
			search: search,
		});
	}

	function navigateToAbout() {
		navigate({
			pathname: `/about`,
			search: search,
		});
	}

	function onSelectedServicesMenuItem(item?: ServicesSection) {
		setOnServicesMenu(false);
		setServicesMenuAnchorElement(null);
		if (item) {
			navigate({
				pathname: `/services`,
				hash: item,
				search: search
			});
		}
	}

	function onSelectedProductsMenuItem(item?: ProductsSection) {
		setOnProductsMenu(false);
		setProductsMenuAnchorElement(null);
		if (item) {
			navigate({
				pathname: `/products`,
				hash: item,
				search: search
			});
		}
	}

	const buttonStyle = {
		height: "100%",
		px: 5,
		textTransform: "none",
		fontWeight: 900,
		fontSize: 17
	}

	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
				px={{ xs: 2, md: 10 }}
				pt={2}
				{...props}>
				<Logo
					sx={{
						width: { xs: 50, md: 100 },
						height: { xs: 45, md: 90 }
					}}
					onClick={() => { navigateToHome() }} />
				<Stack direction="row" spacing={2} sx={{ display: { xs: "none", lg: "block" } }}>
					<Button sx={buttonStyle} onClick={() => { navigateToHome?.() }}>
						{localization.components.navbar.home}
					</Button>
					<Button
						sx={buttonStyle}
						onMouseEnter={(event) => {
							setProductsMenuAnchorElement(event.currentTarget);
							setOnProductsButton(true);
						}}
						onMouseLeave={() => {
							setOnProductsButton(false);
							setProductsMenuAnchorElement(null);
						}}
						onClick={() => { navigateToProducts?.() }}>
						{localization.components.navbar.products}
					</Button>
					<Button
						sx={buttonStyle}
						onMouseEnter={(event) => {
							setServicesMenuAnchorElement(event.currentTarget);
							setOnServicesButton(true);
						}}
						onMouseLeave={() => {
							setOnServicesButton(false);
							setServicesMenuAnchorElement(null);
						}}
						onClick={() => { navigateToServices?.() }}>
						{localization.components.navbar.services}
					</Button>
					<Button sx={buttonStyle} onClick={() => { navigateToAbout?.() }}>
						{localization.components.navbar.aboutUs}
					</Button>
					<Button sx={buttonStyle} onClick={() => { onScrollToGetInTouch?.() }}>
						{localization.components.navbar.getInTouch}
					</Button>
				</Stack>

				<IconButton
					sx={{ display: { xs: "block", lg: "none" } }}
					onClick={() => {
						setShowMobileMenu(!showMobileMenu);
					}}>
					<MenuRoundedIcon />
				</IconButton>
			</Stack>

			<MobileNavbar
				open={showMobileMenu}
				onClose={() => {
					setShowMobileMenu(false);
				}}
				onScrollToGetInTouch={onScrollToGetInTouch}
				onNavigateToHome={navigateToHome}
				onNavigateToProducts={navigateToProducts}
				onNavigateToServices={navigateToServices}
				onNavigateToAbout={navigateToAbout} />

			<ServicesMenu
				anchorEl={servicesMenuAnchorElement}
				open={onServicesButton || onServicesMenu}
				onSelect={onSelectedServicesMenuItem}
				onMouseEnter={() => {
					setOnServicesMenu(true);
				}}
				onMouseLeave={() => {
					setOnServicesMenu(false);
				}} />

			<ProductsMenu
				anchorEl={productsMenuAnchorElement}
				open={onProductsButton || onProductsMenu}
				onSelect={onSelectedProductsMenuItem}
				onMouseEnter={() => {
					setOnProductsMenu(true);
				}}
				onMouseLeave={() => {
					setOnProductsMenu(false);
				}} />
		</>
	);
}