import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import bubblesWebp from "../../../assets/images/bubbles.webp";
import bubblesPng from "../../../assets/images/bubbles.png";
import logo from "../../../assets/images/logo.png";
import app1 from "../../../assets/images/header.png";
import { useInit } from "../../../hooks/useInit";
import VanillaTilt from "vanilla-tilt";
import { useRef } from "react";
import ImgWebp from "../../../components/ImgWebp";
import useLocalization from "../../../hooks/useLocalization";
import CTASection from "./CTASection";

export default function Header({ onGetInTouch, onLearnMore }: { onGetInTouch: () => void, onLearnMore?: () => void }) {
	const theme = useTheme();
	const localization = useLocalization();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const tiltRef = useRef<HTMLDivElement>(null);

	useInit(() => {
		if (tiltRef.current) {
			VanillaTilt.init(tiltRef.current)
		}
	});

	return (
		<Box position="relative">
			<img src={logo} alt="logo" style={{ height: 0, width: 0 }} />
			<ImgWebp src={bubblesWebp} fallback={bubblesPng} alt="" style={{
				position: "absolute",
				top: isMobile ? "-60vw" : "-42vw",
				left: isMobile ? "-100%" : "-18%",
				width: isMobile ? "300%" : "130%",
				maxHeight: "500%",
				zIndex: -10000,
			}} />
			<Grid container pl={{ xs: 1, md: 10 }} pr={{ xs: 1, md: 10, lg: 20 }} alignItems="center" justifyContent="center" spacing={{ xs: 2, lg: 0 }}>
				<Grid item xs={12} lg={5} order={{ xs: 0, lg: 1 }}>
					<Stack alignItems="center" spacing={5}>
						<Stack alignItems="center" spacing={2}>
							<Typography
								component="h1"
								sx={{
									color: "white",
									textAlign: { xs: "center", lg: "left" },
									fontWeight: 900,
									fontSize: { xs: 30, md: 45 },
									lineHeight: { xs: 1.2, md: 1.3 },
								}}>
								{localization.pages.mobileLanding.header.title}
							</Typography>
							<Typography
								component="h2"
								fontSize={{ xs: 14, lg: 16 }}
								textAlign={{ xs: "center", lg: "left" }}>
								{localization.pages.mobileLanding.header.description}
							</Typography>
						</Stack>
						<Box display={{ xs: "none", lg: "block" }}>
							<CTASection onGetInTouch={onGetInTouch} onLearnMore={onLearnMore} />
						</Box>
					</Stack>
				</Grid>
				<Grid item xs={12} md={7} order={{ xs: 1, lg: 0 }}>
					<Box ref={tiltRef}>
						<img src={app1} alt="app1" width="100%" />
					</Box>
				</Grid>
				<Grid item xs={12} order={{ xs: 2 }} display={{ xs: "block", lg: "none" }}>
					<CTASection onGetInTouch={onGetInTouch} onLearnMore={onLearnMore} />
				</Grid>
			</Grid>
		</Box>
	);
}