import { Stack, Typography } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";

export default function Card({ title, description }: { title: string, description: string }) {
	return (
		<Stack
			spacing={2}
			justifyContent="start"
			alignItems="start"
			sx={{
				background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
				borderRadius: "10px",
				px: { xs: 2, md: 5 },
				py: 5,
				height: "100%",
				boxSizing: "border-box",
			}}>
			<GradientTypography
				sx={{
					fontWeight: 900,
					fontSize: { xs: 30, md: 50 }
				}}>
				{title}
			</GradientTypography>
			<Typography
				textAlign="justify">
				{description}
			</Typography>
		</Stack>
	);
}