import SublocksLanguageLocalization from "./SublocksLanguageLocalization";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import DeveloperModeRoundedIcon from '@mui/icons-material/DeveloperModeRounded';
import PodcastsRoundedIcon from '@mui/icons-material/PodcastsRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import ForkLeftRoundedIcon from '@mui/icons-material/ForkLeftRounded';
import { Language } from "../utils/Localization";

const it: SublocksLanguageLocalization = {
	language: Language.it,
	pages: {
		mobileLanding: {
			cta: {
				getInTouch: "Contattaci",
				learnMore: "Scopri di più"
			},
			header: {
				title: "La tua azienda sta sfruttando appieno il potere delle app mobile?",
				description: "Dai vita alla tua visione: creiamo soluzioni centralizzate o basate su blockchain per una presenza digitale incomparabile, sia su web che su dispositivi mobili iOS e Android.",
			},
			skills: {
				title: "Il nostro metodo di lavoro",
				description: "Le fasi che seguiamo per lo sviluppo della tua applicazione di successo",
				items: [
					{
						title: `Analisi\ne design`,
						skills: [
							"Serivece design",
							"User experience",
							"User interface",
							"Wireframing",
							"Prototyping",
							"Usability testing",
						]
					},
					{
						title: "Sviluppo app native e ibride",
						skills: [
							"App iOS native",
							"App Android native",
							"App Flutter ibride",
							"App React Native ibride",
							"Sviluppo backend",
							"Integrazione API"
						]
					},
					{
						title: `Marketing\ne lancio`,
						skills: [
							"App Store Optimization",
							"Google Play Optimization",
							"App Marketing",
							"Stores Management",
							"App Analytics",
							"App Maintenance"
						]
					}
				]
			},
			technologies: {
				title: `Le tecnologie per lo \nsviluppo della tua app`,
				description: "Sviluppiamo app che funzionino perfettamente su tutti i dispositivi\nche operano con il sistema operativo più utilizzato al mondo"
			},
			why: {
				title: "Perché sviluppare un’app per la tua azienda?",
				description: `Il mercato globale delle App sta raggiungendo cifre record, superando i 100 miliardi di dollari, e non mostra segni di rallentamento. Al contrario, continua a crescere rapidamente, e al momento non si intravedono tecnologie in grado di sostituire le applicazioni nel medio termine.`,
				items: [
					{
						title: "Comunicare con i propri clienti senza vincoli, costi, e in qualsiasi momento.",
						icon: PodcastsRoundedIcon,
					},
					{
						title: "Acquisire clienti senza pagare commissioni a piattaforme terze. Riducendo i costi di acquisizione, aumentano i profitti.",
						icon: AttachMoneyRoundedIcon,
					},
					{
						title: "Creare una comunità di utenti fedeli e affezionati al Brand, che scatenano il passaparola positivo con familiari e conoscenti.",
						icon: PeopleOutlineRoundedIcon,
					},
					{
						title: "Aumentare la Brand Awareness ad un costo molto più contenuto, rispetto alle tradizionali campagne pubblicitarie.",
						icon: TrendingUpRoundedIcon,
					},
					{
						title: "Differenziarsi da tutte le aziende concorrenti che ancora non hanno la propria App, acquisendo un vantaggio competitivo.",
						icon: AutoAwesomeRoundedIcon,
					},
					{
						title: "Aumentare l’efficacia del sito, e di tutti gli altri strumenti utilizzati per promuovere l’azienda.",
						icon: PhonelinkRoundedIcon,
					},
					{
						title: "Raccogliere dati utili per migliorare i prodotti e i servizi offerti, e per creare nuove strategie di marketing.",
						icon: QueryStatsRoundedIcon,
					},
					{
						title: "Creare un nuovo canale di vendita, che permette di raggiungere nuovi clienti, e di aumentare le vendite.",
						icon: ForkLeftRoundedIcon,
					}
				]
			},
			portfolio: {
				title: "Alcuni dei nostri progetti",
				description: "App e web3"
			},
			whyUs: {
				title: "Perché scegliere Sublocks",
				description: "Siamo un team di sviluppatori, designer, e marketer con anni di esperienza nel settore.",
				items: [
					{
						icon: AccessTimeRoundedIcon,
						title: "Esperienza",
						description: "Professionisti dello Sviluppo App, con centinaia di App create con successo in tanti settori diversi."
					},
					{
						icon: HelpOutlineRoundedIcon,
						title: "Assistenza continua",
						description: "Finito il progetto non rimarrai solo, perché siamo sempre al tuo fianco per qualsiasi tipo di richiesta/esigenza."
					},
					{
						icon: TuneRoundedIcon,
						title: "Personalizzazione",
						description: "Creiamo un’App personalizzata, che si adatti perfettamente alle esigenze e agli obiettivi della tua azienda."
					},
					{
						icon: HttpsRoundedIcon,
						title: "Sicurezza e privacy",
						description: "La sicurezza e la privacy dei tuoi dati sono la nostra priorità, e per questo utilizziamo le migliori tecnologie disponibili."
					},
					{
						icon: SmartphoneRoundedIcon,
						title: "App per tutti i dispositivi",
						description: "Sviluppiamo App che funzionino perfettamente su tutti i dispositivi che operano con il sistema operativo più utilizzato al mondo."
					},
					{
						icon: TrendingUpRoundedIcon,
						title: "Aumenta le vendite",
						description: "Le App sono uno strumento perfetto per aumentare le vendite, e per fidelizzare i clienti."
					},
					{
						icon: RouteRoundedIcon,
						title: "Dalla A alla Z",
						description: "Seguiamo il tuo progetto dalla A alla Z, e di fornirti tutti i servizi necessari per il successo della tua App."
					},
					{
						icon: DeveloperModeRoundedIcon,
						title: "Sviluppo nativo e ibrido",
						description: "Sviluppiamo App adatte a tutti i dispositivi, sia con tecnologia nativa che ibrida."
					},
				]
			},
			kpi: {
				title: "Partners",
				description: "Sublocks Srl è una divisione specializzata del rinomato Gruppo Manganorobot Srl, leader globale nel settore della robotica e delle nuove tecnologie. Concentrandosi sull'innovazione e la collaborazione sinergica, Sublocks Srl offre soluzioni all'avanguardia, mantenendo un impegno per la qualità e la sostenibilità. Con un team esperto e ambizioni globali, l'azienda sta plasmando il futuro dell'industria blockchain e Web3.",
				kpisLayout: [
					[{ name: "Progetti nel 2023", value: "28" }],
					[
						{ name: "Anni di esperienza", value: "17+" },
						{ name: "Dipendenti", value: "60+" }
					],
					[{ name: "Progetti", value: "500+" }]
				]
			},
		},
		thankYou: {
			title: "GRAZIE",
			description: "Il nostro team ti ricontatterà al più presto per definire con te una proposta personalizzata in base alle tue esigenze.",
			cta: "Continua"
		}
	},
	components: {
		navbar: {
			home: "Home",
			products: "Prodotti",
			services: "Servizi",
			aboutUs: "Su di noi",
			getInTouch: "Contattaci"
		},
		getInTouch: {
			title: "Contattaci",
			description: "Il nostro team ti ricontatterà al più presto per definire con te una proposta personalizzata in base alle tue esigenze.",
			name: "Nome e cognome",
			phone: "Numero di telefono",
			email: "Email",
			message: "Messaggio",
			submitButton: "Invia",
			successMessage: "Messaggio inviato con successo!",
			errorMessage: "Errore nell'invio del messaggio. Riprova più tardi."
		}
	}
}

export default it;