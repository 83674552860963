import { Grid, Stack } from "@mui/material";
import ProjectsSection from '../../components/ProjectsSection';
import TextImageSection from '../../components/TextImageSection';
import TechnologiesSection from './components/TechnologiesSection';
import Nut from "../../assets/images/nut.png";
import Gears from "../../assets/images/gears.png";
import Solidity from "../../assets/images/solidity.png";
import React from "../../assets/images/react.png";
import Flutter from "../../assets/images/flutter.png";
import Swift from "../../assets/images/swift.png";
import Kotlin from "../../assets/images/kotlin.png";
import NodeJS from "../../assets/images/nodejs.png";
import MongoDB from "../../assets/images/mongodb.png";
import Footer from '../../components/Footer';
import GetInTouch from '../../components/GetInTouch';
import { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import Header from './components/Header';
import PageHelmet from "./components/PageHelmet";

export default function Home() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const projectsSection = useRef<HTMLInputElement>(null);
	const getInTouchSection = useRef<HTMLInputElement>(null);

	function scrollToProjects() {
		projectsSection.current?.scrollIntoView({ behavior: "smooth" });
	}

	function scrollToGetInTouch() {
		getInTouchSection.current?.scrollIntoView({ behavior: "smooth" });
	}

	function navigateToProducts() {
		navigate({
			pathname: `/products`,
			search: search,
		});
	}

	function navigateToServicies() {
		navigate({
			pathname: `/services`,
			search: search,
		});
	}

	return (
		<>
			<PageHelmet />
			<Grid container spacing={20} mb={10}>
				<Grid item xs={12}>
					<Stack direction="column-reverse" spacing={10}>
						<Header scrollToProjects={() => { scrollToProjects() }} />
						<Navbar onScrollToGetInTouch={() => { scrollToGetInTouch() }} />
					</Stack>
				</Grid>
				<Grid item ref={projectsSection} xs={12}>
					<ProjectsSection />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						textAt="left"
						title="Products"
						description="We provide a comprehensive range of high-quality products for businesses looking to harness the power of blockchain technology. Our offerings include an advanced smart contract platform, decentralized blockchain-based applications, tokenomics services, and custom NFT collections. We are the ideal partner for businesses and organizations looking for secure, reliable, and customized solutions to meet their specific needs. Discover how we can take your business to the next level with blockchain technology."
						image={Nut}
						buttonTitle="Learn More"
						onButtonClick={() => {
							navigateToProducts();
						}} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						textAt="right"
						title="Services"
						description="Our services will provide you with cutting-edge solutions that will allow you to fully exploit the potential of the next generation Internet. With our expertise and experience in the field, we guarantee high-level services that will transform your online presence. From the design and development of innovative websites to decentralized blockchain applications (such as creating and managing smart contracts, developing DAO platforms, creating NFT collections, and managing blockchain communities and marketing), to the security and optimization of your projects, we are ready to provide you with a superior quality experience. Working with us means creating a forward-looking digital future where excellence is the norm."
						image={Gears}
						buttonTitle="Learn More"
						onButtonClick={() => {
							navigateToServicies();
						}} />
				</Grid>
				{/* <Grid item xs={12}>
					<KPISection
						title="Partners"
						description="Sublocks Srl is a specialized division of the renowned Manganorobot Group Srl, a global leader in the field of robotics and new technologies. Focusing on innovation and synergistic collaboration, Sublocks Srl provides cutting-edge solutions while maintaining a commitment to quality and sustainability. With an experienced team and global ambitions, the company is shaping the future of the industry."
						cardImages={[ManganoRobot, Sinaptica, Deloitte, Leonardo, Comau, ABB]}
						kpisLayout={[
							[{ name: "2022's projects", value: "28+" }],
							[
								{ name: "Years of experience", value: "17+" },
								{ name: "Employees", value: "60+" }
							],
							[{ name: "Projects", value: "500+" }]
						]} />
				</Grid> */}
				<Grid item xs={12}>
					<TechnologiesSection
						title="Technologies"
						description={`Unleashing innovation with the latest technologies\nfrom Blockchain to Web Development, our company creates cutting-edge products`}
						cardImages={[/*Ethereum, Polygon, Binance,*/ Solidity, React, Flutter, Swift, Kotlin, NodeJS, MongoDB]} />
				</Grid>
				<Grid item ref={getInTouchSection} xs={12}>
					<GetInTouch />
				</Grid>
				<Grid item xs={12}>
					<Footer />
				</Grid>
			</Grid>
		</>
	);
}