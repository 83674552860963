import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";
import bubblesWebp from "../../../assets/images/bubbles.webp";
import bubblesPng from "../../../assets/images/bubbles.png";
import { Fade } from "@gioxxy/react-awesome-reveal";
import ImgWebp from "../../../components/ImgWebp";

export default function KPISection({ title, description, cardImages }: { title: string, description: string, cardImages: string[] }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Stack
			spacing={{ xs: 3, lg: 5 }}
			justifyContent="center"
			alignItems="center"
			px={{ xs: 5, lg: 25 }}>
			<GradientTypography
				sx={{
					fontWeight: 900,
					fontSize: { xs: 40, md: 50 }
				}}>
				{title}
			</GradientTypography>
			<Typography
				textAlign="center">
				{description}
			</Typography>
			<Box position="relative" height="100%" width="100%">
				<ImgWebp
					src={bubblesWebp}
					fallback={bubblesPng}
					alt="bubbles"
					style={{
						position: "absolute",
						top: "-120%",
						left: "-150%",
						width: "auto",
						height: "250%",
						zIndex: -1,
						display: isMobile ? "block" : "none"
					}} />
				<Grid container spacing={1} justifyContent="center" alignItems="center">
					{cardImages.map((cardImage: string, index: number) => {
						return (
							<Grid item xs={12 / 2} sm={12 / 4} md={12 / 5} key={index}>
								<Fade direction="right" delay={index * 100}>
									<Box sx={{
										background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
										borderRadius: "10px",
										py: 3,
										px: 4
									}}>
										<img src={cardImage} alt="card" style={{ width: "100%" }} />
									</Box>
								</Fade>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</Stack>
	);
} 