import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useInit } from "../hooks/useInit";

export class AlertDialogAction {
	label: string;
	positive?: boolean;
	onClick?: () => void;

	constructor(label: string, positive: boolean = false, onClick?: () => void) {
		this.label = label;
		this.positive = positive;
		this.onClick = onClick;
	}
}

export class AlertDialogController {
	show = (title: string, description: string, actions?: AlertDialogAction[], dismissable: boolean = true) => { };
	hide = () => { };
	onDismiss = () => { };
}

export const AlertDialog = ({ controller }: { controller: AlertDialogController }) => {
	const [title, setTitle] = useState<string | undefined>();
	const [description, setDescription] = useState<string | undefined>();
	const [actions, setActions] = useState<JSX.Element[]>();
	const [dismissable, setDismissable] = useState<boolean>(true);
	const [show, setShow] = useState<boolean>(false);

	useInit(() => {
		controller.show = (title: string, description: string, actions?: AlertDialogAction[], dismissable: boolean = true) => {
			setTitle(title);
			setDescription(description);
			setActions(
				actions?.map((action) => (
					<Button
						key={action.label}
						sx={{ fontWeight: 800, color: action.positive ? "secondary.main" : "text.main" }}
						onClick={() => {
							setShow(false);
							action.onClick?.();
						}}>
						{action.label}
					</Button>
				))
			);
			setDismissable(dismissable);
			setShow(true);
		};

		controller.hide = () => {
			setShow(false);
		};
	});

	function onClose() {
		if (dismissable) {
			setShow(false);
			controller.onDismiss();
		}
	}

	return (
		<Dialog
			open={show}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{
				elevation: 1,
				style: {
					borderRadius: 15,
					paddingTop: 20,
				},
			}}>
			<DialogTitle id="alert-dialog-title" color="secondary" sx={{ fontWeight: 800 }}>
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" color="text.main">
					{description}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{actions}
			</DialogActions>
		</Dialog>
	);
};
