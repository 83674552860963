import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";
import { Fade } from "@gioxxy/react-awesome-reveal";
import useLocalization from "../../../hooks/useLocalization";
import { LineChart } from "@mui/x-charts";

export default function WhySection() {
	const theme = useTheme();
	const localization = useLocalization();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Grid
			container
			spacing={5}
			justifyContent="center"
			alignItems="center"
			px={{ xs: 2, md: 10, lg: 25 }}>
			<Grid
				item
				xs={12}
				lg={6}>
				<GradientTypography
					sx={{
						fontWeight: 900,
						fontSize: { xs: 40, md: 50 },
						textAlign: { xs: "center", lg: "left" },
						mb: { xs: 2, lg: 2 }
					}}>
					{localization.pages.mobileLanding.why.title}
				</GradientTypography>
				<Typography
					textAlign={{ xs: "center", lg: "left" }}
					fontSize={16}>
					{localization.pages.mobileLanding.why.description}
				</Typography>
				<LineChart
					xAxis={[
						{
							scaleType: "point",
							data: [2016, 2018, 2019, 2020, 2021, 2022],
							label: "Annual apps download",
							hideTooltip: true,
						}
					]}
					yAxis={[
						{
							scaleType: "linear",
							min: 140,
							max: 260,
							tickNumber: 3,
							disableTicks: true,
							disableLine: true,
							valueFormatter: (d) => `${d}B`,
						}
					]}
					series={[
						{
							id: "data",
							data: [140.67, 192.45, 204, 218, 230, 255],
							color: "#FF9900",
							valueFormatter: (d) => `${d}B`,
							area: true,
						},
					]}
					height={isMobile ? 300 : 500}
					sx={{
						'& .MuiLineElement-root': {
							strokeWidth: 4,
						},
						'& .MuiChartsAxis-tick': {
							display: "none",
						},
						'& .MuiChartsAxis-line': {
							stroke: "#212121",
							display: "none",
						},
						'& .MuiChartsAxis-tickLabel': {
							stroke: "#5d5d5d",
						},
						'& .MuiAreaElement-series-data': {
							fill: "url('#areaGradient')",
						},
					}}
				>
					<defs>
						<linearGradient id="areaGradient" gradientTransform="rotate(90)">
							<stop offset="0%" stopColor="#FF990061" />
							<stop offset="60%" stopColor="transparent" />
						</linearGradient>
					</defs>
				</LineChart>
			</Grid>
			<Grid
				item
				xs={12}
				lg={6}>
				<Stack spacing={1} justifyContent="center" alignItems="center" pt={2}>
					{localization.pages.mobileLanding.why.items.map((item, index) => {
						return (
							<Fade direction="up" delay={index * 10} key={index} triggerOnce>
								<Box sx={{
									background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
									width: "fill-available",
									borderRadius: "10px",
									py: 2,
									px: 3
								}}>
									<Stack direction="row" alignItems="center" justifyContent="start" spacing={2}>
										{/* <GradientTypography fontWeight={800}>{index + 1}</GradientTypography> */}
										<svg width="0" height="0">
											<defs>
												<linearGradient
													id="iconGradient"
													gradientTransform="rotate(45)">
													<stop stopColor="#FF9900" />
													<stop offset="1" stopColor="#CD18A5" />
												</linearGradient>
											</defs>
										</svg>
										<item.icon sx={{
											fill: "url(#iconGradient)",
										}} />
										<Typography fontWeight={600}>{item.title}</Typography>
									</Stack>
								</Box>
							</Fade>
						);
					})}
				</Stack>
			</Grid>
		</Grid>
	);
} 