import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";
import bubblesWebp from "../../../assets/images/bubbles.webp";
import bubblesPng from "../../../assets/images/bubbles.png";
import ImgWebp from "../../../components/ImgWebp";
import useLocalization from "../../../hooks/useLocalization";
import Flutter from "../../../assets/images/flutter.png";
import Swift from "../../../assets/images/swift.png";
import Kotlin from "../../../assets/images/kotlin.png";
import NodeJS from "../../../assets/images/nodejs.png";
import MongoDB from "../../../assets/images/mongodb.png";
import iOS from "../../../assets/images/ios.png";
import Android from "../../../assets/images/android.png";

export default function TechnologiesSection() {
	const theme = useTheme();
	const localization = useLocalization();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const cardImages = [Flutter, Swift, Kotlin, NodeJS, MongoDB /*, Ethereum, Polygon, Binance, Solidity, React*/];

	return (
		<Stack
			spacing={{ xs: 3, lg: 5 }}
			justifyContent="center"
			alignItems="center"
			px={{ xs: 2, md: 10, lg: 25 }}>
			<Stack spacing={2}>
				<GradientTypography
					sx={{
						fontWeight: 900,
						fontSize: { xs: 40, md: 50 },
						textAlign: "center"
					}}>
					{localization.pages.mobileLanding.technologies.title}
				</GradientTypography>
				<Typography
					textAlign="center"
					fontSize={16}>
					{localization.pages.mobileLanding.technologies.description}
				</Typography>
			</Stack>
			<Box position="relative" height="100%" width="100%">
				<ImgWebp
					src={bubblesWebp}
					fallback={bubblesPng}
					alt="bubbles"
					style={{
						position: "absolute",
						top: "-120%",
						left: "-150%",
						width: "auto",
						height: "250%",
						zIndex: -1,
						display: isMobile ? "block" : "none"
					}} />
				<Grid container spacing={1} justifyContent="center" alignItems="center">
					<Grid item xs={0} sm={3} display={{ xs: "none", sm: "block" }} />
					<Grid item xs={6} sm={3}>
						<Box sx={{
							background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
							borderRadius: "10px",
							py: { xs: 3, lg: 6 },
							px: { xs: 4, lg: 8 }
						}}>
							<img src={iOS} alt="card" style={{ width: "100%" }} />
						</Box>
					</Grid>
					<Grid item xs={6} sm={3}>
						<Box sx={{
							background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
							borderRadius: "10px",
							py: { xs: 3, lg: 6 },
							px: { xs: 4, lg: 8 }
						}}>
							<img src={Android} alt="card" style={{ width: "100%" }} />
						</Box>
					</Grid>
					<Grid item xs={0} sm={3} display={{ xs: "none", sm: "block" }} />
					{cardImages.map((cardImage: string, index: number) => {
						return (
							<Grid item xs={12 / 2} sm={12 / 4} md={12 / 5} key={index}>
								<Box sx={{
									background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
									borderRadius: "10px",
									py: 3,
									px: 4
								}}>
									<img src={cardImage} alt="card" style={{ width: "100%" }} />
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</Stack>
	);
} 