import { PaletteColorOptions, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
	interface CustomPalette {
		button: PaletteColorOptions;
		dark: PaletteColorOptions;
	}
	interface Palette extends CustomPalette { }
	interface PaletteOptions extends CustomPalette { }
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		button: true;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		text: true;
	}
}

declare module '@mui/material/LinearProgress' {
	interface LinearProgressPropsColorOverrides {
		dark: true;
	}
}

export default createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: "#FFFFFF",
		},
		secondary: {
			main: "#E4C560",
		},
		background: {
			default: "#000000",
			paper: "#000000",
		},
		button: {
			main: "#1B180C"
		},
		dark: {
			main: "#000000"
		},
		text: {
			primary: "#FFFFFF",
			secondary: "#000000",
		}
	},
	typography: {
		fontFamily: 'Montserrat Variable',
		allVariants: {
			color: "#fff",
			whiteSpace: "pre-line",
		}
	}
});