export default class ValidableTextFieldController {
	value?: string;
	error?: string;

	get hasError(): boolean {
		return !!this.error;
	}

	validation: (value?: string) => boolean = (_) => true;
	validate: (value?: string) => boolean = (value) => {
		return this.validation(value);
	}

	constructor({ value, error, validation }: { value?: string, error?: string, validation?: (value?: string) => boolean } = {}) {
		this.value = value;
		this.error = error;
		this.validation = validation || this.validation;
	}

	clearError() {
		this.error = undefined;
	}

	clear() {
		this.value = undefined;
		this.clearError();
	}
}