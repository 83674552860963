import { Stack, Grid } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";
import TeamMember from "../models/TeamMember";
import TeamMemberCard from "./TeamMemberCard";
import { Fade } from "@gioxxy/react-awesome-reveal";

export default function TeamSection({ teamMembers }: { teamMembers: TeamMember[] }) {
	return (
		<Fade direction="up">
			<Stack spacing={5} alignItems="start" px={{ xs: 2, md: 5, lg: 25 }}>
				<GradientTypography
					sx={{
						fontWeight: 900,
						fontSize: { xs: 30, md: 50 }
					}}>
					Team
				</GradientTypography>
				<Grid container justifyContent="start" alignItems="strech">
					{teamMembers.map((teamMember: TeamMember, index: number) => {
						return (
							<Grid item key={index} xs={12} sm={6} lg={4}>
								<Fade direction="right" delay={index * 100}>
									<TeamMemberCard teamMember={teamMember} />
								</Fade>
							</Grid>
						);
					})}
				</Grid>
			</Stack>
		</Fade>
	);
}