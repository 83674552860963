import SublocksLanguageLocalization from "./SublocksLanguageLocalization";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import DeveloperModeRoundedIcon from '@mui/icons-material/DeveloperModeRounded';
import PodcastsRoundedIcon from '@mui/icons-material/PodcastsRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import ForkLeftRoundedIcon from '@mui/icons-material/ForkLeftRounded';
import { Language } from "../utils/Localization";

const en: SublocksLanguageLocalization = {
	language: Language.en,
	pages: {
		mobileLanding: {
			cta: {
				getInTouch: "Get In Touch",
				learnMore: "Learn More"
			},
			header: {
				title: "Is your company taking full advantage of the power of mobile apps?",
				description: "Bring your vision to life: we develop centralized or blockchain-based solutions for an unparalleled digital presence, both on web and iOS and Android mobile platforms.",
			},
			skills: {
				title: "Our working method",
				description: "The phases we follow for the development of your successful application",
				items: [
					{
						title: "Analysis and design",
						skills: [
							"Serivece design",
							"User experience",
							"User interface",
							"Wireframing",
							"Prototyping",
							"Usability testing",
						]
					},
					{
						title: "Native and hybrid app development",
						skills: [
							"Native iOS app",
							"Native Android app",
							"Hybrid Flutter app",
							"Hybrid React Native app",
							"Backend development",
							"API integration"
						]
					},
					{
						title: "Marketing and launch",
						skills: [
							"App Store Optimization",
							"Google Play Optimization",
							"App Marketing",
							"Stores Management",
							"App Analytics",
							"App Maintenance"
						]
					}
				]
			},
			technologies: {
				title: "The technology stack for your app",
				description: "We develop apps that work seamlessly on all devices\nrunning the world's most widely used operating system"
			},
			why: {
				title: "Why develop an app for your company?",
				description: `The global App market is reaching record numbers, exceeding $100 billion, and shows no signs of slowing down. On the contrary, it continues to grow rapidly, and there are currently no technologies in sight that can replace apps in the medium term.`,
				items: [
					{
						title: "Communicate with your customers without constraints, costs, and at any time.",
						icon: PodcastsRoundedIcon
					},
					{
						title: "Acquire customers without paying commissions to third-party platforms. By reducing acquisition costs, increase profits.",
						icon: AttachMoneyRoundedIcon
					},
					{
						title: "Create a community of users who are loyal and devoted to the Brand, triggering positive word-of-mouth with family members and acquaintances.",
						icon: PeopleOutlineRoundedIcon
					},
					{
						title: "Increase Brand Awareness at a much lower cost, compared to traditional advertising campaigns.",
						icon: TrendingUpRoundedIcon
					},
					{
						title: "Differentiate themselves from all competing companies that do not yet have their own App, gaining a competitive advantage.",
						icon: AutoAwesomeRoundedIcon
					},
					{
						title: "Build a social platform where users also interact with each other, becoming a community.",
						icon: PhonelinkRoundedIcon
					},
					{
						title: "Increase the effectiveness of the website, and all other tools used to promote the company.",
						icon: QueryStatsRoundedIcon
					},
					{
						title: "Create a new sales channel, which allows you to reach new customers, and increase sales.",
						icon: ForkLeftRoundedIcon
					},
				]
			},
			portfolio: {
				title: "Some of our projects",
				description: "App and web3"
			},
			whyUs: {
				title: "Why choose Sublocks",
				description: "We are a team of developers, designers, and marketers with years of industry experience.",
				items: [
					{
						icon: AccessTimeRoundedIcon,
						title: "Experience",
						description: "App Development Professionals, with hundreds of successfully created Apps in many different fields."
					},
					{
						icon: HelpOutlineRoundedIcon,
						title: "Continuous support",
						description: "Once the project is over, you will not be alone, because we are always by your side for any kind of request/need."
					},
					{
						icon: TuneRoundedIcon,
						title: "Customization",
						description: "We create a customized App that perfectly fits your company's needs and goals."
					},
					{
						icon: HttpsRoundedIcon,
						title: "Security and privacy",
						description: "The security and privacy of your data is our priority, which is why we use the best technologies available."
					},
					{
						icon: SmartphoneRoundedIcon,
						title: "App for all devices",
						description: "We develop Apps that work perfectly on all devices running the world's most widely used operating system."
					},
					{
						icon: TrendingUpRoundedIcon,
						title: "Increases sales",
						description: "Apps are a perfect tool for increasing sales, and building customer loyalty."
					},
					{
						icon: RouteRoundedIcon,
						title: "A to Z",
						description: "We follow your project from A to Z, and provide you with all the services you need to make your App a success."
					},
					{
						icon: DeveloperModeRoundedIcon,
						title: "Native and hybrid development",
						description: "We develop Apps suitable for all devices, both with native and hybrid technology."
					},
				]
			},
			kpi: {
				title: "Partners",
				description: "Sublocks Srl is a specialized division of the renowned Manganorobot Group Srl, a global leader in the field of robotics and new technologies. Focusing on innovation and synergistic collaboration, Sublocks Srl provides cutting-edge solutions while maintaining a commitment to quality and sustainability. With an experienced team and global ambitions, the company is shaping the future of the industry.",
				kpisLayout: [
					[{ name: "2022's projects", value: "28" }],
					[
						{ name: "Years of experience", value: "17+" },
						{ name: "Employees", value: "60+" }
					],
					[{ name: "Projects", value: "500+" }]
				]
			},
		},
		thankYou: {
			title: "THANK YOU",
			description: "We will contact you as soon as possible to define with you a personalized proposal based on your needs.",
			cta: "Continue"
		}
	},
	components: {
		navbar: {
			home: "Home",
			products: "Products",
			services: "Services",
			aboutUs: "About Us",
			getInTouch: "Get In Touch"
		},
		getInTouch: {
			title: "Get In Touch",
			description: "Our team will contact you as soon as possible to define with you a personalized proposal based on your needs.",
			name: "Name and surname",
			phone: "Phone number",
			email: "Email",
			message: "Message",
			submitButton: "Submit",
			successMessage: "Message sent successfully!",
			errorMessage: "Error sending message. Try again later."
		}
	}
}

export default en;