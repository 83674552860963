import { Helmet } from "react-helmet-async";
import useLocalization from "../../../hooks/useLocalization";
import { Language } from "../../../utils/Localization";

export default function PageHelmet() {
	const localization = useLocalization();

	return (
		<Helmet>
			{"<!-- HTML Meta Tags -->"}
			<title>Sublocks</title>
			<meta name="title" content="Sublocks" />
			<meta name="description" content="Decentralization, Your Way: Empowering Your Projects through Smart Contracts, NFTs, and DApps!" />

			{"<!-- Google / Search Engine Tags -->"}
			<meta itemProp="name" content="Sublocks" />
			<meta itemProp="description" content="Decentralization, Your Way: Empowering Your Projects through Smart Contracts, NFTs, and DApps!" />
			<meta itemProp="image" content="https://sublocks.com/banner.png" />

			{
				(localization.language === Language.en) ?
					<link rel="canonical" href="https://sublocks.com/mobile" />
					:
					<link rel="canonical" href={`https://sublocks.com/${localization.language}/mobile`} />
			}

			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "https://schema.org",
					"@type": "Organization",
					"url": "https://sublocks.com",
					"logo": "https://sublocks.com/logo.png"
				})}
			</script>

			{"<!-- Facebook Meta Tags -->"}
			<meta property="og:url" content="https://sublocks.com" />
			<meta property="og:type" content="website" />
			<meta property="og:title" content="Sublocks" />
			<meta property="og:description" content="Decentralization, Your Way: Empowering Your Projects through Smart Contracts, NFTs, and DApps!" />
			<meta property="og:image" content="https://sublocks.com/banner.png" />

			{"<!-- Twitter Meta Tags -->"}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content="Sublocks" />
			<meta name="twitter:description" content="Decentralization, Your Way: Empowering Your Projects through Smart Contracts, NFTs, and DApps!" />
			<meta name="twitter:image" content="https://sublocks.com/banner.png" />
		</Helmet>
	);
}