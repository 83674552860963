import { Box, IconButton, Stack, Typography } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";
import TeamMember from "../models/TeamMember";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

export default function TeamMemberCard({ teamMember, ...props }: { teamMember: TeamMember }) {

	function openLinkedIn(linkedin: string) {
		window.open(linkedin, "_blank");
	}

	function openEmail(email: string) {
		window.open(`mailto:${email}`, "_blank");
	}

	return (
		<Box position="relative" width="100%" height="100%" pt="10px" my="20px" {...props}>
			<img
				src={teamMember.image}
				alt="profile pic"
				style={{
					position: "absolute",
					left: 0,
					top: 0,
					aspectRatio: 1,
					width: "110px"
				}} />
			<Stack
				spacing={1}
				justifyContent="start"
				alignItems="start"
				sx={{
					ml: "10px",
					background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
					borderRadius: "10px",
					px: { xs: 2, md: 5 },
					pt: 4,
					pb: 2
				}}>
				<Stack
					justifyContent="start"
					alignItems="start"
					mb={2}
					ml={{ xs: "90px", md: "70px" }}>
					<GradientTypography
						sx={{
							fontWeight: 900,
							fontSize: 20
						}}>
						{teamMember.name}
					</GradientTypography>
					<Typography
						fontWeight={800}>
						{teamMember.role}
					</Typography>
				</Stack>
				<Typography>
					{teamMember.description}
				</Typography>
				<Stack
					direction="row"
					justifyContent="start"
					alignItems="center">
					{teamMember.linkedin &&
						<IconButton
							onClick={() => {
								if (teamMember.linkedin) openLinkedIn(teamMember.linkedin);
							}}>
							<LinkedInIcon />
						</IconButton>}
					{teamMember.email &&
						<IconButton
							onClick={() => {
								if (teamMember.email) openEmail(teamMember.email);
							}}>
							<EmailIcon />
						</IconButton>}
				</Stack>
			</Stack>
		</Box >
	);
}