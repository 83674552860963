import { useRef } from 'react';
import { Grid, Stack } from "@mui/material";
import PortfolioSection from './components/PortfolioSection';
import TechnologiesSection from './components/TechnologiesSection';
import Footer from '../../components/Footer';
import GetInTouch from '../../components/GetInTouch';
import Navbar from './components/Navbar';
import Header from './components/Header';
import PageHelmet from "./components/PageHelmet";
import useLocalization from "../../hooks/useLocalization";
import SkillsSection from './components/SkillsSection';
import WhySection from './components/WhySection';
import WhyUsSection from './components/WhyUsSection';
import CTASection from './components/CTASection';

export default function MobileLanding() {
	const localization = useLocalization();
	const skillsSection = useRef<HTMLInputElement>(null);
	const whySection = useRef<HTMLInputElement>(null);
	const kpiSection = useRef<HTMLInputElement>(null);
	const getInTouchSection = useRef<HTMLInputElement>(null);

	function scrollToSkills() {
		skillsSection.current?.scrollIntoView({ behavior: "smooth" });
	}

	function scrollToWhy() {
		whySection.current?.scrollIntoView({ behavior: "smooth" });
	}

	function scrollToKPI() {
		kpiSection.current?.scrollIntoView({ behavior: "smooth" });
	}

	function scrollToGetInTouch() {
		getInTouchSection.current?.scrollIntoView({ behavior: "smooth" });
	}

	return (
		<>
			<PageHelmet />
			<Grid container spacing={{ xs: 10, lg: 20 }} mb={10}>
				<Grid item xs={12}>
					<Stack direction="column-reverse" spacing={{ xs: 0, lg: 10 }}>
						<Header onLearnMore={() => { scrollToSkills() }} onGetInTouch={() => { scrollToGetInTouch() }} />
						<Navbar onScrollToGetInTouch={() => { scrollToGetInTouch() }} />
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<TechnologiesSection />
				</Grid>
				<Grid item ref={whySection} xs={12}>
					<WhySection />
				</Grid>
				<Grid item xs={12}>
					<CTASection onLearnMore={() => { scrollToWhy() }} onGetInTouch={() => { scrollToGetInTouch() }} />
				</Grid>
				<Grid item ref={skillsSection} xs={12}>
					<SkillsSection />
				</Grid>
				<Grid item xs={12}>
					<PortfolioSection
						title={localization.pages.mobileLanding.portfolio.title}
						description={localization.pages.mobileLanding.portfolio.description} />
				</Grid>
				<Grid item xs={12}>
					<WhyUsSection />
				</Grid>
				<Grid item xs={12}>
					<CTASection onLearnMore={() => { scrollToKPI() }} onGetInTouch={() => { scrollToGetInTouch() }} />
				</Grid>
				{/* <Grid item ref={kpiSection} xs={12}>
					<KPISection />
				</Grid> */}
				<Grid item ref={getInTouchSection} xs={12}>
					<GetInTouch />
				</Grid>
				<Grid item xs={12}>
					<Footer />
				</Grid>
			</Grid>
		</>
	);
}