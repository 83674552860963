import { Backdrop, Stack, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useInit } from "../hooks/useInit";

export class LoaderController {
	showLoader = (message?: string, transparent: boolean = true) => { };
	hideLoader = () => { };
}

export const Loader = ({ controller }: { controller: LoaderController }) => {
	const [message, setMessage] = useState<string | undefined>();
	const [show, setShow] = useState<boolean>(false);
	const [transparent, setTransparent] = useState<boolean>(true);

	useInit(() => {
		controller.showLoader = (message?: string, transparent: boolean = true) => {
			setMessage(message);
			setTransparent(transparent);
			setShow(true);
		};

		controller.hideLoader = () => {
			setMessage(undefined);
			setShow(false);
		};
	});

	return (
		<Backdrop
			sx={{ zIndex: 10000000, backgroundColor: transparent ? "rgba(0, 0, 0, 0.5)" : "#000" }}
			open={show}
			onClick={() => { }}>
			<Stack direction="column" spacing={2} alignItems="center">
				<CircularProgress color="secondary" />
				<Typography sx={{ color: "white", textAlign: "center", display: message ? "block" : "none" }}>
					{message}
				</Typography>
			</Stack>
		</Backdrop>
	);
};
