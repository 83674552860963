import { Box } from "@mui/material";
import GradientTypography from "./GradientTypography";

export default function Chip({ ...props }) {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "relative",
				"&:before": {
					content: '""',
					position: "absolute",
					inset: 0,
					borderRadius: "10px",
					border: "3px solid transparent",
					background: "linear-gradient(45deg, #FF7D34 30%, #FF008A 130%) border-box",
					WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);",
					WebkitMaskComposite: "xor",
					maskComposite: "exclude",
				}
			}}>
			<GradientTypography
				gradient="45deg, #FF7D34 30%, #FF008A 130%"
				marginLeft={20}
				marginRight={20}
				marginTop={10}
				marginBottom={10}
				fontSize={15}
				fontWeight={700}>
				{props.children}
			</GradientTypography>
		</Box>
	);
}