import app0 from '../assets/images/apps/0.png';
import app1 from '../assets/images/apps/1.png';
import app2 from '../assets/images/apps/2.png';
import app3 from '../assets/images/apps/3.png';
import app4 from '../assets/images/apps/4.png';
import app5 from '../assets/images/apps/5.png';
import app6 from '../assets/images/apps/6.png';
import app7 from '../assets/images/apps/7.png';
import app8 from '../assets/images/apps/8.png';
import app9 from '../assets/images/apps/9.png';
import app10 from '../assets/images/apps/10.png';
import app11 from '../assets/images/apps/11.png';
import app12 from '../assets/images/apps/12.png';
import app13 from '../assets/images/apps/13.png';
import app14 from '../assets/images/apps/14.png';
import app15 from '../assets/images/apps/15.png';
import app16 from '../assets/images/apps/16.png';
import app17 from '../assets/images/apps/17.png';
import app18 from '../assets/images/apps/18.png';
import app19 from '../assets/images/apps/19.png';

const AppImages = [
	app0,
	app1,
	app2,
	app3,
	app4,
	app5,
	app6,
	app7,
	app8,
	app9,
	app10,
	app11,
	app12,
	app13,
	app14,
	app15,
	app16,
	app17,
	app18,
	app19,
];

export default AppImages;