import { Collapse, Alert } from "@mui/material";
import { useState } from "react";
import { useInit } from "../hooks/useInit";

export class MessagesController {
	showMessage = (title: string) => { };

	showError = (title: string) => { };
}

export const Messages = ({ controller }: { controller: MessagesController }) => {
	const [message, setMessage] = useState<string | undefined>();
	const [error, setError] = useState<string | undefined>();

	useInit(() => {
		controller.showMessage = (title: string) => {
			setMessage(title);
			setTimeout(() => {
				setMessage(undefined);
			}, 5000);
		};

		controller.showError = (title: string) => {
			setError(title);
			setTimeout(() => {
				setError(undefined);
			}, 5000);
		};
	});

	return (
		<>
			<Collapse
				in={!!message}
				sx={{ position: "fixed", width: "100%", top: 0, right: 0, zIndex: 10000000 }}
			>
				<Alert
					variant="standard"
					severity="success"
					style={{
						fontSize: 15,
					}}
					sx={{ mt: 2, mx: 2 }}
				>
					{message}
				</Alert>
			</Collapse>
			<Collapse
				in={!!error}
				sx={{ position: "fixed", width: "100%", top: 0, right: 0, zIndex: 10000000 }}
			>
				<Alert
					variant="standard"
					severity="error"
					style={{
						fontSize: 15,
					}}
					sx={{ mt: 2, mx: 2 }}
				>
					{error}
				</Alert>
			</Collapse>
		</>
	);
};
