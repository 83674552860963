import { Popper, Stack, Button } from "@mui/material";
import ServicesSection from "../../models/ServicesSection";

export default function ServicesMenu({
	anchorEl,
	open,
	onSelect,
	onMouseEnter,
	onMouseLeave,
	...props
}: {
	anchorEl?: HTMLElement | (() => HTMLElement) | null | undefined,
	open: boolean,
	onSelect?: (section: ServicesSection) => void,
	onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined,
	onMouseLeave?: React.MouseEventHandler<HTMLDivElement> | undefined,
	[key: string]: any
}) {
	const menuItemStyle = {
		fontWeight: 900,
		fontSize: 17,
		justifyContent: "center",
		py: 2,
		px: 5,
		borderRadius: "10px",
		mx: 2,
		"&:hover": {
			background: `-webkit-linear-gradient(45deg, #FF9900 30%, #CD18A5 90%)`,
			WebkitBackgroundClip: "text",
			WebkitTextFillColor: "transparent"
		}
	}

	return (
		<Popper
			id="fade-menu"
			anchorEl={anchorEl}
			placement="bottom"
			open={open}
		>
			<Stack
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				sx={{
					background: "#FFFFFF0D", // "#00000026", "#FFFFFF0D",
					borderRadius: "10px",
					mt: "-5px",
					pt: 1,
					pb: 1,
					minWidth: "360px",
					boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
					backdropFilter: "blur(50px)",
				}}>
				<Button
					key={ServicesSection.SmartContracts}
					sx={menuItemStyle}
					onClick={() => onSelect?.(ServicesSection.SmartContracts)}>
					Smart Contracts
				</Button>
				<Button
					key={ServicesSection.DApps}
					sx={menuItemStyle}
					onClick={() => onSelect?.(ServicesSection.DApps)}>
					Web3 DApp
				</Button>
				<Button
					key={ServicesSection.Tokens}
					sx={menuItemStyle}
					onClick={() => onSelect?.(ServicesSection.Tokens)}>
					Token & Tokenomics
				</Button>
				<Button
					key={ServicesSection.NFTs}
					sx={menuItemStyle}
					onClick={() => onSelect?.(ServicesSection.NFTs)}>
					NFT Collections
				</Button>
				<Button
					key={ServicesSection.Marketing}
					sx={menuItemStyle}
					onClick={() => onSelect?.(ServicesSection.Marketing)}>
					Marketing & Communication
				</Button>
				<Button
					key={ServicesSection.Advising}
					sx={menuItemStyle}
					onClick={() => onSelect?.(ServicesSection.Advising)}>
					Web3 Advising
				</Button>
			</Stack>
		</Popper>
	);
}