import { Grid } from "@mui/material";
import TextImageSection from "../../components/TextImageSection";
import Gears from "../../assets/images/gears.png";
import Footer from "../../components/Footer";
import GetInTouch from "../../components/GetInTouch";
import { useEffect, useRef } from "react";
import ProjectsSection from "../../components/ProjectsSection";
import SmartContract from "../../assets/images/smartcontract.png";
import Dapp from "../../assets/images/dapp.png";
import Tokens from "../../assets/images/tokens.png";
import NFT from "../../assets/images/nft.png";
import Marketing from "../../assets/images/marketing.png";
import Navbar from "../../components/navbar/Navbar";
import ServicesSection from "../../models/ServicesSection";
import { useLocation } from "react-router-dom";
import PageHelmet from "./components/PageHelmet";

export default function Services() {
	const location = useLocation();
	const getInTouchSection = useRef<HTMLInputElement>(null);

	function scrollToGetInTouch() {
		getInTouchSection.current?.scrollIntoView({ behavior: "smooth" });
	}

	useEffect(() => {
		if (location.hash) {
			const hash = location.hash.substring(1);
			const element = document.getElementById(hash);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [location]);

	return (
		<>
			<PageHelmet />
			<Grid container spacing={20} mb={10}>
				<Grid item xs={12}>
					<Navbar onScrollToGetInTouch={scrollToGetInTouch} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						textAt="left"
						title="Services"
						description="Our services will provide you with cutting-edge solutions that will allow you to fully exploit the potential of the next generation Internet. With our expertise and experience in the field, we guarantee high-level services that will transform your online presence. From the design and development of innovative websites to decentralized blockchain applications (such as creating and managing smart contracts, developing DAO platforms, creating NFT collections, and managing blockchain communities and marketing), to the security and optimization of your projects, we are ready to provide you with a superior quality experience. Working with us means creating a forward-looking digital future where excellence is the norm."
						image={Gears}
						buttonTitle="Get In Touch"
						onButtonClick={() => { scrollToGetInTouch() }} />
				</Grid>
				<Grid item xs={12}>
					<ProjectsSection />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ServicesSection.SmartContracts}
						textAt="right"
						title="Smart Contracts"
						description="Our company provides information technology and blockchain technology services related to smart contracts on blockchain. Our services include smart contract creation, management, execution, and verification for companies that want to automate their business processes. We also provide consulting, software development, and verification and validation services. Smart contracts are autonomous programs executed on a blockchain that automate the process of executing agreements and contracts between two parties, eliminating the need for intermediaries. Our services are part of the emerging technologies that are transforming the way businesses manage their operations and transactions."
						tags={["Companies", "Individuals"]}
						image={SmartContract} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ServicesSection.DApps}
						textAt="left"
						title="Web3 DApp"
						description="We specialize in developing decentralized blockchain-based applications and offer dashboard and dapp creation services. We have extensive knowledge of blockchain technology and can develop secure and reliable applications on various blockchains such as Ethereum, Polygon and Binance Smart Chain. We create user-friendly interfaces for dashboards and Dapps, and provide testing and auditing services to ensure the security and functionality of the application. With our technical expertise and experience, Sublocks is the reliable and professional partner for creating blockchain dashboards and DApps."
						image={Dapp}
						tags={["Companies", "Individuals"]} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ServicesSection.Tokens}
						textAt="right"
						title="Token &
						Tokenomics"
						description="Our company provides innovative tokenomics services for the creation of blockchain ecosystems. Our experienced teams of experts provide a range of services, including the creation of token distribution, incentive, governance and burn models. We also offer customized consulting services to help clients identify their blockchain ecosystem goals and tailor their tokenomics accordingly. We are committed to providing our clients with high-quality services and sustainable solutions, and to contributing to the development of cutting-edge blockchain ecosystems. We are a reliable, knowledgeable, and professional choice for clients seeking robust tokenomics to achieve their goals in a cost-effective manner."
						tags={["Companies", "Individuals"]}
						image={Tokens} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ServicesSection.NFTs}
						textAt="left"
						title="NFT Collections"
						description="We also offer a complete service in the NFT field. We create and market customized NFT collections (2D, 3D, video, audio, etc...) for artists, designers and entrepreneurs, and provide advice on pricing and transaction management with buyers. We market collections on sales platforms such as OpenSea, Rarible and Coinbase NFT, providing accessible services to authenticate and monetize digital creations."
						image={NFT}
						tags={["Companies", "Individuals"]} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ServicesSection.Marketing}
						textAt="right"
						title="Marketing &
						Communication"
						description="We have a corporate branch specializing in blockchain marketing strategies and social community management services. We customize marketing strategies, identify target audiences, and select appropriate channels. We also manage social community accounts, create content, organize events, and place advertisements on blockchain-related websites and newsletters. We are the professional choice to promote blockchain projects."
						tags={["Companies", "Individuals"]}
						image={Marketing} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ServicesSection.Advising}
						textAt="left"
						title="Web3 Advising"
						description="As Web3 Advisors, we offer expert guidance in navigating the decentralized landscape. From startups to enterprises, we tailor strategies for embracing Web3, Crypto Tokens, and NFTs. Our transparent and collaborative approach ensures seamless integration and tangible results. With a pulse on industry trends, we equip you with the knowledge and tools to harness decentralized technologies effectively. We are the professional choice to guide you through the Web3 revolution."
						image={SmartContract}
						tags={["Companies"]} />
				</Grid>
				<Grid item ref={getInTouchSection} xs={12}>
					<GetInTouch />
				</Grid>
				<Grid item xs={12}>
					<Footer />
				</Grid>
			</Grid>
		</>
	);
}