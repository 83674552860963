import { Box, Grid, Stack, Typography } from "@mui/material";
import GradientTypography from "./GradientTypography";
import DefaultButton from "./DefaultButton";
import bubblesWebp from "../assets/images/bubbles.webp";
import bubblesPng from "../assets/images/bubbles.png";
import { Fade } from "@gioxxy/react-awesome-reveal";
import Chip from "./Chip";
import ImgWebp from "./ImgWebp";

export default function TextImageSection({ textAt = "left", title, description, image, tags, buttonTitle, onButtonClick = () => { }, ...props }: { textAt?: "left" | "right", title: string, description: string, tags?: string[], buttonTitle?: string, image: string, onButtonClick?: () => void, [key: string]: any }) {
	return (
		<Grid container
			direction={textAt === "left" ? "row" : "row-reverse"}
			spacing={{ xs: 20, sm: 2, lg: 20 }}
			justifyContent="center"
			alignItems="center"
			px={{ xs: 5, lg: 25 }}
			{...props}>
			<Grid item key="text" xs={12} sm={6}>
				<Fade direction={textAt === "left" ? "left" : "right"} duration={500}>
					<Stack spacing={{ xs: 3, lg: 5 }} alignItems="start">
						<GradientTypography
							sx={{
								fontWeight: 900,
								fontSize: { xs: 40, md: 50 }
							}}>
							{title}
						</GradientTypography>
						<Typography
							textAlign="left">
							{description}
						</Typography>
						<Grid container
							direction={{ xs: "column-reverse", md: "row" }}
							spacing={2}
							alignItems={{ xs: "start", md: "center" }}
							justifyContent="start">
							{buttonTitle &&
								<Grid item>
									<DefaultButton
										onClick={() => {
											onButtonClick?.();
										}}>
										{buttonTitle}
									</DefaultButton>
								</Grid>}
							<Grid item>
								<Grid container
									spacing={2}
									alignItems="center"
									justifyContent="start">
									{tags?.map((tag: string, index: number) => {
										return (
											<Grid item key={index}>
												<Chip>
													{tag}
												</Chip>
											</Grid>
										);
									})}
								</Grid>
							</Grid>
						</Grid>
					</Stack>
				</Fade>
			</Grid>
			<Grid item key="img" xs={12} sm={6} zIndex={-100}>
				<Box position="relative" height="100%" width="100%">
					<ImgWebp
						src={bubblesWebp}
						fallback={bubblesPng}
						alt="bubbles"
						style={{
							position: "absolute",
							top: "-60%",
							left: textAt === "left" ? "-60%" : "-80%",
							width: "250%",
							height: "auto",
							zIndex: -1,
						}} />
					<Fade direction={textAt === "left" ? "right" : "left"} delay={100} duration={500} style={{ height: "100%", width: "100%" }}>
						<img src={image} alt="section" style={{ width: "100%" }} />
					</Fade>
				</Box>
			</Grid>
		</Grid >
	);
} 