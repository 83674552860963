import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import DefaultButton from "../../../components/DefaultButton";
import bubblesWebp from "../../../assets/images/bubbles.webp";
import bubblesPng from "../../../assets/images/bubbles.png";
import logo from "../../../assets/images/logo.png";
import { useInit } from "../../../hooks/useInit";
import VanillaTilt from "vanilla-tilt";
import { useRef } from "react";
import ImgWebp from "../../../components/ImgWebp";

export default function Header({ scrollToProjects }: { scrollToProjects?: () => void }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const tiltRef = useRef<HTMLDivElement>(null);

	useInit(() => {
		if (tiltRef.current) {
			VanillaTilt.init(tiltRef.current)
		}
	});

	return (
		<Stack>
			<>
				{/* <Box mt={-30}>
						<Spline scene="https://prod.spline.design/lG4fWCyCEyiegwkF/scene.splinecode" style={{ width: "100%", aspectRatio: "16/9" }} />
					</Box> */}

				<Box position="relative">
					<img src={logo} alt="logo" style={{ height: 0, width: 0 }} />
					<ImgWebp src={bubblesWebp} fallback={bubblesPng} alt="" style={{
						position: "absolute",
						top: isMobile ? "-60vw" : "-42vw",
						left: isMobile ? "-50%" : "-18%",
						width: isMobile ? "200%" : "130%",
						maxHeight: "500%",
						zIndex: -10000,
					}} />
					<Stack ref={tiltRef} alignItems="center" spacing={5} px={2}>
						<Stack alignItems="center" spacing={2} px={2}>
							<Typography
								component="h1"
								sx={{
									color: "white",
									textAlign: "center",
									fontWeight: 900,
									fontSize: { xs: 50, md: 100 }
								}}>
								SUBLOCKS
							</Typography>
							<Typography
								component="h2"
								fontSize={20}
								textAlign="center">
								<b>Decentralization, Your Way</b ><br />
								Empowering Your Projects through Smart Contracts, NFTs, and DApps!
							</Typography>
						</Stack>
						<DefaultButton
							mt={20}
							onClick={() => {
								scrollToProjects?.();
							}}>
							Explore
						</DefaultButton>
					</Stack>
				</Box>
			</>
		</Stack>
	);
}