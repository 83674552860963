export default function ImgWebp({
	src,
	fallback,
	alt,
	type = 'image/webp',
	...props
}: {
	src: string,
	fallback: string,
	alt: string,
	type?: string,
	[key: string]: any
}) {
	return (
		<picture>
			<source srcSet={src} type={type} />
			<img src={fallback} alt={alt} {...props} />
		</picture>
	);
};