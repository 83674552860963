import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import bubblesWebp from "../../../assets/images/bubbles.webp";
import bubblesPng from "../../../assets/images/bubbles.png";
import DefaultButton from "../../../components/DefaultButton";
import { Fade } from "@gioxxy/react-awesome-reveal";
import ImgWebp from "../../../components/ImgWebp";

export default function Header({ onGetInTouchClick }: { onGetInTouchClick?: () => void }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Fade direction="up">
			<Box position="relative">
				<ImgWebp
					src={bubblesWebp}
					fallback={bubblesPng}
					alt="bubbles"
					style={{
						position: "absolute",
						top: isMobile ? "-90vw" : "-42vw",
						left: isMobile ? "-100%" : "-18%",
						width: isMobile ? "300%" : "130%",
						zIndex: -10000,
					}} />
				<Stack alignItems="center" spacing={5} px={2}>
					<Typography
						component="h1"
						sx={{
							color: "white",
							textAlign: "center",
							fontWeight: 900,
							fontSize: { xs: 30, md: 50 }
						}}>
						Meet Our Team<br />
						Driving the Future of Web3
					</Typography>
					<Typography
						component="h2"
						fontSize={20}
						textAlign="center"
						maxWidth={{ xs: "100%", md: "50%" }}>
						The collaborative minds determined to empower individuals through Web3.
					</Typography>
					<DefaultButton
						onClick={() => {
							onGetInTouchClick?.();
						}}>
						Get In Touch
					</DefaultButton>
				</Stack>
			</Box>
		</Fade>
	);
}