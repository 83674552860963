import { Box, Grid, Stack, Typography } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";
import { Fade } from "@gioxxy/react-awesome-reveal";
import useLocalization from "../../../hooks/useLocalization";

export default function WhySection() {
	const localization = useLocalization();

	return (
		<Stack
			spacing={{ xs: 3, lg: 5 }}
			justifyContent="center"
			alignItems="center"
			px={{ xs: 2, md: 10, lg: 25 }}>
			<Stack spacing={2}>
				<GradientTypography
					sx={{
						fontWeight: 900,
						fontSize: { xs: 40, md: 50 },
						textAlign: "center",
					}}>
					{localization.pages.mobileLanding.whyUs.title}
				</GradientTypography>
				<Typography
					textAlign="center"
					fontSize={16}>
					{localization.pages.mobileLanding.whyUs.description}
				</Typography>
			</Stack>
			<Grid container spacing={1} justifyContent="center" alignItems="center" pt={2}>
				{localization.pages.mobileLanding.whyUs.items.map((item, index) => {
					return (
						<Grid item xs={12} lg={6} key={index}>
							<Fade direction="up" delay={index * 10} key={index} triggerOnce>
								<Box sx={{
									background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
									width: "fill-available",
									borderRadius: "10px",
									py: 2,
									px: 3
								}}>
									<Stack direction="row" spacing={2} alignItems="center">
										<svg width="0" height="0">
											<defs>
												<linearGradient
													id="iconGradient"
													gradientTransform="rotate(45)">
													<stop stopColor="#FF9900" />
													<stop offset="1" stopColor="#CD18A5" />
												</linearGradient>
											</defs>
										</svg>
										<item.icon sx={{
											fill: "url(#iconGradient)",
										}} />
										<Stack direction="column">
											<Typography fontWeight={800}>{item.title}</Typography>
											<Typography>{item.description}</Typography>
										</Stack>
									</Stack>
								</Box>
							</Fade>
						</Grid>
					);
				})}
			</Grid>
		</Stack >
	);
} 