import { Backdrop, Stack, IconButton, Button } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Logo from "../Logo";

export default function MobileNavbar({
	open,
	onClose,
	onScrollToGetInTouch,
	onNavigateToHome,
	onNavigateToProducts,
	onNavigateToServices,
	onNavigateToAbout,
	...props
}: {
	open: boolean,
	onClose?: () => void,
	onScrollToGetInTouch?: () => void,
	onNavigateToHome?: () => void,
	onNavigateToProducts?: () => void,
	onNavigateToServices?: () => void,
	onNavigateToAbout?: () => void,
	[key: string]: any
}) {
	const mobileButtonStyle = {
		width: "90%",
		py: 2,
		px: 5,
		textTransform: "none",
		fontWeight: 900,
		fontSize: 17
	}

	return (
		<Backdrop
			sx={{
				zIndex: 10000000,
				backgroundColor: "rgba(0, 0, 0, 0.95)",
				margin: "0px !important",
			}}
			open={open}
			onClick={() => { onClose?.() }}>
			<Stack direction="column" height="100%" width="100%">
				<Stack
					direction="row"
					justifyContent="space-between"
					px={{ xs: 2, md: 10 }}
					pt={2}
					{...props}>
					<Logo
						sx={{
							width: { xs: 50, md: 100 },
							height: { xs: 45, md: 90 }
						}}
						onClick={() => { onNavigateToHome?.() }} />
					<IconButton
						onClick={() => {
							onClose?.();
						}}>
						<CloseRoundedIcon />
					</IconButton>
				</Stack>
				<Stack direction="column" height="100%" spacing={2} alignItems="center" justifyContent="center">
					<Button sx={mobileButtonStyle} onClick={() => { onNavigateToHome?.() }}>
						Home
					</Button>
					<Button sx={mobileButtonStyle} onClick={() => { onNavigateToProducts?.() }}>
						Products
					</Button>
					<Button sx={mobileButtonStyle} onClick={() => { onNavigateToServices?.() }}>
						Services
					</Button>
					<Button sx={mobileButtonStyle} onClick={() => { onNavigateToAbout?.() }}>
						About
					</Button>
					<Button sx={mobileButtonStyle} onClick={() => { onScrollToGetInTouch?.() }}>
						Get In Touch
					</Button>
				</Stack>
			</Stack>
		</Backdrop>
	);
}