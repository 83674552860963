import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import MuiFade from "@mui/material/Fade";
import GradientTypography from "./GradientTypography";
import DefaultButton from "./DefaultButton";
import { Fade } from "@gioxxy/react-awesome-reveal";
import ValidableTextFieldController from "../models/ValidableTextFieldController";
import { messagesController } from "../App";
import ValidableTextField from "./ValidableTextField";
import { useRef, useState } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import successAnimation from "../assets/lotties/success.json";
import useLocalization from "../hooks/useLocalization";
import { useLocation } from "react-router-dom";
import { getLocalizedPath } from "../utils/Localization";

export default function GetInTouch() {
	const theme = useTheme();
	const { search } = useLocation();
	const localization = useLocalization();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
	const [showSuccess, setShowSuccess] = useState(false);
	const successAnimationRef = useRef<LottieRefCurrentProps | null>(null);

	const [nameTextFieldController] = useState(new ValidableTextFieldController({
		validation: (value) => {
			if (!!value) {
				nameTextFieldController.clearError();
				return true;
			}
			nameTextFieldController.error = "Invalid name";
			return false;
		}
	}));
	const [phoneTextFieldController] = useState(new ValidableTextFieldController({
		validation: (value) => {
			if (!!value && value.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{3,5}$/gm) !== null) {
				phoneTextFieldController.clearError();
				return true;
			}
			phoneTextFieldController.error = "Invalid phone number";
			return false;
		}
	}));
	const [emailTextFieldController] = useState(new ValidableTextFieldController({
		validation: (value) => {
			if (String(value)
				.toLowerCase()
				.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null) {
				emailTextFieldController.clearError();
				return true;
			}
			emailTextFieldController.error = "Invalid email";
			return false;
		}
	}));
	const [messageTextFieldController] = useState(new ValidableTextFieldController({
		validation: (value) => {
			if (!!value) {
				messageTextFieldController.clearError();
				return true;
			}
			messageTextFieldController.error = "Invalid message";
			return false;
		}
	}));

	async function sendEmail() {
		let validationRes = nameTextFieldController.validate(nameTextFieldController.value);
		validationRes = phoneTextFieldController.validate(phoneTextFieldController.value) && validationRes;
		validationRes = emailTextFieldController.validate(emailTextFieldController.value) && validationRes;
		validationRes = messageTextFieldController.validate(messageTextFieldController.value) && validationRes;

		if (validationRes) {
			try {
				const response = await fetch(
					"https://api.elasticemail.com/v4/emails/transactional",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							'X-ElasticEmail-ApiKey': process.env.REACT_APP_ELASTICEMAIL_API_KEY || "",
						},
						body: JSON.stringify({
							"Recipients": {
								"To": [
									"enrico.sacchi@sublocks.com",
									"veniamin.zama@sublocks.com"
								],
								"CC": [
									"gionatan.cernusco@sublocks.com",
									"daniele.mangano@sublocks.com"
								]
							},
							"Content": {
								"EnvelopeFrom": `${nameTextFieldController.value} <${emailTextFieldController.value}>`,
								"From": `sublocks-mailer@proton.me`,
								"ReplyTo": `${nameTextFieldController.value} <${emailTextFieldController.value}>`,
								"Subject": `Sublocks Contact Form ${nameTextFieldController.value} ${phoneTextFieldController.value}`,
								"Body": [
									{
										"ContentType": "PlainText",
										"Content": `${messageTextFieldController.value}\n\n${nameTextFieldController.value}\n${phoneTextFieldController.value}\n${emailTextFieldController.value}\n\n\nUnsubscribe:`,
										"Charset": "iso-8859-1"
									}
								],
							},
						}),
					}
				);
				if (response.ok) {
					setShowSuccess(true);
					successAnimationRef.current?.stop();
					successAnimationRef.current?.play();
					nameTextFieldController.clear();
					phoneTextFieldController.clear();
					emailTextFieldController.clear();
					messageTextFieldController.clear();
					setTimeout(() => {
						setShowSuccess(false);
						successAnimationRef.current?.stop();
						window.open(getLocalizedPath("/thankyou-page", localization.language) + search);
					}, 2000);
				}
			} catch (e) {
				messagesController.showError(localization.components.getInTouch.errorMessage);
			}
		}
	}

	const textFieldStyle = {
		input: {
			px: 3,
		},
		fieldset: {
			borderRadius: "10px",
			border: "3px solid transparent",
			background: "linear-gradient(45deg, #FF9900 30%, #CD18A5 90%) border-box",
			WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);",
			WebkitMaskComposite: "xor",
			maskComposite: "exclude",
		},
		"&:hover": {
			fieldset: {
				borderRadius: "10px",
				border: "3px solid transparent",
				background: "linear-gradient(45deg, #FF9900 30%, #CD18A5 90%) border-box",
				WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);",
				WebkitMaskComposite: "xor",
				maskComposite: "exclude",
			}
		}
	};

	return (
		<Fade direction="up" triggerOnce>
			<Stack
				spacing={{ xs: 3, lg: 5 }}
				justifyContent="center"
				alignItems="center"
				sx={{
					background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
					borderRadius: "10px",
					mx: { xs: 1, md: 5, lg: 25 },
					px: { xs: 2, md: 5, lg: 25 },
					py: 5,
				}}>
				<GradientTypography
					sx={{
						fontWeight: 900,
						fontSize: { xs: 40, md: 50 }
					}}>
					{localization.components.getInTouch.title}
				</GradientTypography>
				<Typography
					fontSize={20}
					textAlign="center">
					{localization.components.getInTouch.description}
				</Typography>
				<Box position="relative" width="100%">
					<MuiFade in={!showSuccess}>
						<Stack
							spacing={5}
							justifyContent="center"
							alignItems="center"
							width="100%">
							<Stack
								spacing={2}
								justifyContent="center"
								alignItems="center"
								width="100%">
								<ValidableTextField
									controller={nameTextFieldController}
									fullWidth
									placeholder={localization.components.getInTouch.name}
									sx={textFieldStyle} />
								<ValidableTextField
									controller={phoneTextFieldController}
									fullWidth
									placeholder={localization.components.getInTouch.phone}
									sx={textFieldStyle} />
								<ValidableTextField
									controller={emailTextFieldController}
									fullWidth
									placeholder={localization.components.getInTouch.email}
									sx={textFieldStyle} />
								<ValidableTextField
									controller={messageTextFieldController}
									fullWidth
									multiline
									maxRows={10}
									minRows={isMobile ? 6 : 10}
									placeholder={localization.components.getInTouch.message}
									sx={textFieldStyle} />
							</Stack>
							<DefaultButton
								onClick={() => {
									sendEmail();
								}}>
								{localization.components.getInTouch.submitButton}
							</DefaultButton>
						</Stack>
					</MuiFade>
					<Box position="absolute" top="10%" left="30%" width="40%" display={showSuccess ? "block" : "none"}>
						<MuiFade in={showSuccess}>
							<Box>
								<Lottie
									lottieRef={successAnimationRef}
									animationData={successAnimation}
									autoPlay={false}
									loop={false} />
							</Box>
						</MuiFade>
					</Box>
				</Box>
			</Stack>
		</Fade>
	);
}