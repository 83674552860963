import { styled } from '@mui/material/styles';

const GradientTypography = styled('h1')<{
	gradient?: string,
	[key: string]: any
}>(({ gradient = '45deg, #FF9900 30%, #CD18A5 90%', ...props }) => ({
	fontSize: 20,
	background: `-webkit-linear-gradient(${gradient})`,
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
	whiteSpace: "pre-line",
	margin: 0,
	...props
}));

export default GradientTypography;