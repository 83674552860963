import { Grid } from "@mui/material";
import TextImageSection from "../../components/TextImageSection";
import Nut from "../../assets/images/nut.png";
import Dao from "../../assets/images/dao.png";
import Network from "../../assets/images/network.png";
import Swap from "../../assets/images/swap.png";
import Footer from "../../components/Footer";
import GetInTouch from "../../components/GetInTouch";
import { useEffect, useRef } from "react";
import Navbar from "../../components/navbar/Navbar";
import ProductsSection from "../../models/ProductsSection";
import { useLocation } from "react-router-dom";
import PageHelmet from "./components/PageHelmet";

export default function Products() {
	const location = useLocation();
	const getInTouchSection = useRef<HTMLInputElement>(null);

	function scrollToGetInTouch() {
		getInTouchSection.current?.scrollIntoView({ behavior: "smooth" });
	}

	useEffect(() => {
		if (location.hash) {
			const hash = location.hash.substring(1);
			const element = document.getElementById(hash);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [location]);

	return (
		<>
			<PageHelmet />
			<Grid container spacing={20} mb={10}>
				<Grid item xs={12}>
					<Navbar onScrollToGetInTouch={scrollToGetInTouch} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						textAt="left"
						title="Products"
						description="We provide a comprehensive range of high-quality products for businesses looking to harness the power of blockchain technology. Our offerings include an advanced smart contract platform, decentralized blockchain-based applications, tokenomics services, and custom NFT collections. We are the ideal partner for businesses and organizations looking for secure, reliable, and customized solutions to meet their specific needs. Discover how we can take your business to the next level with blockchain technology."
						image={Nut}
						buttonTitle="Get In Touch"
						onButtonClick={() => { scrollToGetInTouch() }} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ProductsSection.DAO}
						textAt="right"
						title="DAO"
						description="The DAO is a platform that empowers the community, allowing individuals to vote and voice their opinions on company proposals, attend meetings, and earn income from their assets. These are just a few of the opportunities available through our DAO. Contact us to understand how you can incorporate a DAO into your company and reap all the benefits."
						tags={["Companies"]}
						image={Dao} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ProductsSection.MLMNetwork}
						textAt="left"
						title="MLM Network"
						description="Most crypto projects rely on large networks that offer marketing, sales incentives and prize plans managed by dedicated software. This software can manage a complex system of rewards at different levels for all individuals involved in sales. Our technology division has developed its own software and rewards system to reach large communities that are not organized in this way. This product is unique because it is the first fully decentralized network software created specifically for us, but marketable throughout the blockchain galaxy."
						image={Network}
						tags={["Companies"]} />
				</Grid>
				<Grid item xs={12}>
					<TextImageSection
						id={ProductsSection.Swap}
						textAt="right"
						title="SWAP"
						description="Swappy Trade is a decentralized wallet that allows you to exchange and buy digital coins. The unique feature of this product is the ability to swap coins between different blockchains without having to remove them from your wallet. This provides a high level of security and an all-in-one solution for your needs. Our technology can also be marketed to other exchanges and traders."
						tags={["Companies", "Individuals"]}
						image={Swap} />
				</Grid>
				<Grid item ref={getInTouchSection} xs={12}>
					<GetInTouch />
				</Grid>
				<Grid item xs={12}>
					<Footer />
				</Grid>
			</Grid>
		</>
	);
}