import { Helmet } from "react-helmet-async";

export default function PageHelmet() {
	return (
		<Helmet>
			{"<!-- HTML Meta Tags -->"}
			<title>Sublocks - Products</title>
			<meta name="title" content="Sublocks - Products" />
			<meta name="description" content="Decentralization, Your Way: Empowering Your Projects through Smart Contracts, NFTs, and DApps!" />

			{"<!-- Google / Search Engine Tags -->"}
			<meta itemProp="name" content="Sublocks - Products" />
			<meta itemProp="description" content="Decentralization, Your Way: Empowering Your Projects through Smart Contracts, NFTs, and DApps!" />
			<meta itemProp="image" content="https://sublocks.com/banner.png" />

			<link rel="canonical" href="https://sublocks.com/products" />

			{"<!-- Facebook Meta Tags -->"}
			<meta property="og:url" content="https://sublocks.com/products" />
			<meta property="og:type" content="website" />
			<meta property="og:title" content="Sublocks - Products" />
			<meta property="og:description" content="Decentralization, Your Way: Empowering Your Projects through Smart Contracts, NFTs, and DApps!" />
			<meta property="og:image" content="https://sublocks.com/banner.png" />

			{"<!-- Twitter Meta Tags -->"}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content="Sublocks - Products" />
			<meta name="twitter:description" content="Decentralization, Your Way: Empowering Your Projects through Smart Contracts, NFTs, and DApps!" />
			<meta name="twitter:image" content="https://sublocks.com/banner.png" />
		</Helmet>
	);
}