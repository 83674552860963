import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Language, Localization } from "../utils/Localization";
import { SublocksLanguageLocalizationContext } from "../utils/Contexts";
import SublocksLanguageLocalization from "../localizations/SublocksLanguageLocalization";

export default function LocalizationProvider({ localization, children }: { localization: Localization<SublocksLanguageLocalization>, children: any }) {
	const location = useLocation();
	const [currentLocalization, setCurrentLocalization] = useState(localization.current);

	useEffect(() => {
		const paths = location.pathname.split("/");
		if (paths.length > 1 && !!(paths[1] as Language)) {
			if(paths[1] !== localization.current.language){
				console.log("Setting language to:", paths[1]);
				localization.setLanguage(paths[1] as Language);
				setCurrentLocalization(localization.current);
			}
		} else {
			if(localization.current.language !== Language.en){
				console.log("Setting language to default:", Language.en);
				localization.setLanguage(Language.en);
				setCurrentLocalization(localization.current);
			}
		}
	}, [location.pathname, localization]);

	return (
		<SublocksLanguageLocalizationContext.Provider value={currentLocalization}>
			{children}
		</SublocksLanguageLocalizationContext.Provider>
	);
}
