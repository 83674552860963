import { Grid, Stack } from "@mui/material";
import { Fade } from "@gioxxy/react-awesome-reveal";
import Footer from "../../components/Footer";
import GetInTouch from "../../components/GetInTouch";
import { useRef } from "react";
import Navbar from "../../components/navbar/Navbar";
import Header from "./components/Header";
import Card from "./components/Card";
import TeamSection from "./components/TeamSection";
import DanieleMangano from "../../assets/images/daniele-mangano.png";
import GionatanCernusco from "../../assets/images/gionatan-cernusco.png";
import EnricoSacchi from "../../assets/images/enrico-sacchi.png";
import PageHelmet from "./components/PageHelmet";


export default function About() {
	const getInTouchSection = useRef<HTMLInputElement>(null);
	const teamMembers = [
		{
			name: "DANIELE MANGANO",
			role: "CEO",
			description: "Daniele Mangano is a serial entrepreneur who is active with his companies in the areas of industrial automation, biometric recognition, blockchain technology, and digital currencies.",
			image: DanieleMangano,
			linkedin: "https://www.linkedin.com/in/mangano-daniele",
			email: "daniele.mangano@sublocks.com"
		},
		{
			name: "GIONATAN CERNUSCO",
			role: "CTO",
			description: "Experienced and entrepreneurial IT professional with a passion for exploring new technologies, including mobile, blockchain, and Web 3.0. Successful founder of several startups.",
			image: GionatanCernusco,
			linkedin: "https://www.linkedin.com/in/gionatan-cernusco",
			email: "gionatan.cernusco@sublocks.com"
		},
		{
			name: "ENRICO SACCHI",
			role: "Community Manager",
			description: "Worked with major real estate entrepreneurs and investor with various shares anchored in assets especially in the NFT field.",
			image: EnricoSacchi,
			linkedin: "https://www.linkedin.com/in/enrico-sacchi-a2b952137",
			email: "enrico.sacchi@sublocks.com"
		}
	];

	function scrollToGetInTouch() {
		getInTouchSection.current?.scrollIntoView({ behavior: "smooth" });
	}

	return (
		<>
			<PageHelmet />
			<Grid container spacing={20} mb={10}>
				<Grid item xs={12}>
					<Stack direction="column-reverse" spacing={20}>
						<Header onGetInTouchClick={() => {
							scrollToGetInTouch();
						}} />
						<Navbar onScrollToGetInTouch={scrollToGetInTouch} />
					</Stack>
				</Grid>
				<Grid item ref={getInTouchSection} xs={12}>
					<Fade direction="up" triggerOnce>
						<Grid container spacing={2} px={{ xs: 2, lg: 25 }}>
							<Grid item xs={12} sm={6}>
								<Fade direction="up" delay={100} style={{ height: "100%" }}>
									<Card
										title="Our Vision"
										description={"At Sublocks, we envision a decentralized future where digital ownership, privacy, and trust are paramount. As pioneers in Web3 development, Crypto Tokens, and NFT solutions, our mission is to empower businesses and individuals, revolutionizing interactions and transactions through cutting-edge decentralized technologies.\n\nTogether, we shape a more inclusive and user-centric internet, opening new doors of opportunity and forging the path towards a decentralized tomorrow."} />
								</Fade>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Fade direction="up" delay={200} style={{ height: "100%" }}>
									<Card
										title="Our Role"
										description={"At Sublocks, we are more than service providers; we are collaborative partners dedicated to understanding our clients' unique needs in Web3, Crypto Tokens, and NFT solutions. Through open communication, innovative thinking, and secure implementation, we empower their success in the ever-evolving decentralized landscape.\n\nTogether, we drive meaningful change and usher in a new era of decentralized possibilities."} />
								</Fade>
							</Grid>
						</Grid>
					</Fade>
				</Grid>
				<Grid item xs={12}>
					<TeamSection teamMembers={teamMembers} />
				</Grid>
				<Grid item ref={getInTouchSection} xs={12}>
					<GetInTouch />
				</Grid>
				<Grid item xs={12}>
					<Footer />
				</Grid>
			</Grid>
		</>
	);
}