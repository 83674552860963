import { Box, ThemeProvider } from '@mui/material';
import Theme from './components/Theme';
import { Loader, LoaderController } from './components/Loader';
import { AlertDialog, AlertDialogController } from './components/Dialog';
import { Messages, MessagesController } from './components/Messages';
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Products from './pages/products/Products';
import Services from './pages/services/Services';
import About from './pages/about/About';
import ScrollToTop from './components/ScrollToTop';
import MobileLanding from "./pages/mobileLanding/MobileLanding";
import SublocksLocalization from './localizations/SublocksLocalization';
import { Language } from './utils/Localization';
import LocalizationProvider from './components/LocalizationProvider';
import CookiesBanner from './components/CookiesBanner';
import ThankYou from './pages/thankyou/Thankyou';

export const loaderController = new LoaderController();
export const alertDialogController = new AlertDialogController();
export const messagesController = new MessagesController();

function App() {
	const localization = SublocksLocalization(Language.en);

	const routes = <>
		<Route index element={<Home />} />
		<Route path="products" element={<Products />} />
		<Route path="services" element={<Services />} />
		<Route path="about" element={<About />} />
		<Route path="mobile" element={<MobileLanding />} />
		<Route path="thankyou-page" element={<ThankYou />} />
		<Route path="*" element={<Home />} />
	</>;

	return (
		<HelmetProvider>
			<ThemeProvider theme={Theme}>
				<Box className="App" overflow="hidden">
					<Loader controller={loaderController} />
					<AlertDialog controller={alertDialogController} />
					<Messages controller={messagesController} />
					<BrowserRouter>
						<LocalizationProvider localization={localization}>
							<ScrollToTop>
								<Routes>
									<Route path="/" >
										{routes}
									</Route>
									<Route path="/:lang">
										{routes}
									</Route>
								</Routes>
							</ScrollToTop>
						</LocalizationProvider>
					</BrowserRouter>
					<CookiesBanner />
				</Box>
			</ThemeProvider>
		</HelmetProvider>
	);
}

export default App;
