import { Button } from '@mui/material';

function DefaultButton({ ...props }: { [key: string]: any }) {
	return (
		<Button
			sx={{
				color: "#000",
				backgroundColor: "#fff",
				fontSize: { xs: 15, md: 17 },
				fontWeight: 800,
				borderRadius: "50px",
				textTransform: "none",
				px: "100px",
				py: "20px",
				'&:hover': {
					backgroundColor: '#f2f2f2'
				},
				...props.sx
			}}
			{...(({sx, ...rest}) => rest)(props)}> { props.children }</Button >
	);
}

export default DefaultButton;