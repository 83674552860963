import { Grid, Stack, Typography } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";
import AppImages from "../../../utils/AppImages";
import { Img } from "../../../utils/Img";

export default function PortfolioSection({ title, description }: { title: string, description: string }) {

	const transition = {
		animationName: "loop",
		animationTimingFunction: "linear",
		animationIterationCount: "infinite",
		animationDirection: "normal",
		animationDuration: "40s",
		"@keyframes loop": {
			"0%": {
				transform: "translateX(0%)"
			},
			"100%": {
				transform: "translateX(-50%)"
			}
		}
	}

	const images = [
		[AppImages[10], AppImages[11]],
		[AppImages[4]],
		[AppImages[2], AppImages[3]],
		[AppImages[0]],
		[AppImages[8], AppImages[9]],
		[AppImages[7]],
		[AppImages[5], AppImages[6]],
		[AppImages[1]],
		[AppImages[12], AppImages[13]],
		[AppImages[0]],
		[AppImages[15], AppImages[16]],
		[AppImages[14]],
		[AppImages[17], AppImages[18]],
		[AppImages[19]],
		/*1*/
		[AppImages[10], AppImages[11]],
		[AppImages[4]],
		[AppImages[2], AppImages[3]],
		[AppImages[0]],
		[AppImages[8], AppImages[9]],
		[AppImages[7]],
		[AppImages[5], AppImages[6]],
		[AppImages[1]],
		[AppImages[12], AppImages[13]],
		[AppImages[0]],
		[AppImages[15], AppImages[16]],
		[AppImages[14]],
		[AppImages[17], AppImages[18]],
		[AppImages[19]],
	];

	return (
		<Stack alignItems="start" spacing={{ xs: 2, lg: 10 }}>
			<Stack alignItems="center" width="fill-available" px={{ xs: 2, lg: 25 }}>
				<GradientTypography
					sx={{
						fontWeight: 900,
						fontSize: { xs: 35, md: 50 },
						textAlign: "center"
					}}
				>
					{title}
				</GradientTypography>
				<Typography
					height={1}
					fontWeight={900}
					fontSize={{ xs: 25, md: 45 }}
					textAlign="center">
					{description}
				</Typography>
			</Stack>

			<Grid container alignItems="center" width={{ xs: "1000%", md: "600%", lg: "300%" }} sx={transition}>
				{images.map((image, index) => {
					return (
						<Grid item xs={12 / 30} key={index}>
							<Stack spacing={4} mx={2}>
								{image.map((img, index) => {
									return (
										<Img src={img} alt={`app${index}`} width="100%" borderRadius="16px" key={index} />
									);
								}
								)}
							</Stack>
						</Grid>
					);
				})
				}
			</Grid>
		</Stack>
	);
}