import { useEffect, useState } from "react";

export function useInit(init: Function) {
	const [mounted, setMounted] = useState(false)

	const resetInit = () => setMounted(false)

	useEffect(() => {
		if (!mounted) {
			setMounted(true);
			init();
		}
	}, [mounted, init]);

	return [resetInit]
}