import { Grid, Stack, Typography } from "@mui/material";
import PageHelmet from "../home/components/PageHelmet";
import useLocalization from "../../hooks/useLocalization";
import Logo from "../../components/Logo";
import DefaultButton from "../../components/DefaultButton";

export default function ThankYou() {
	const localization = useLocalization();

	const closePage = () => {
			window.close();
	}

	return (
		<>
			<PageHelmet />
			<Grid container spacing={{ xs: 30, lg: 20 }} mb={10}>
				<Grid item xs={12}>
					<Stack
						direction="row"
						justifyContent="space-between"
						px={{ xs: 2, md: 10 }}
						pt={2}>
						<Logo
							sx={{
								width: { xs: 50, md: 100 },
								height: { xs: 45, md: 90 }
							}} />
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<Stack alignItems="center">
						<Typography
							component="h1"
							sx={{
								color: "white",
								textAlign: "center",
								fontWeight: 900,
								fontSize: { xs: 50, md: 100 },
								lineHeight: { xs: 1.2, md: 1.3 },
							}}>
							{localization.pages.thankYou.title}
						</Typography>
						<Typography
							component="h2"
							fontSize={{ xs: 14, lg: 16 }}
							textAlign="center">
							{localization.pages.thankYou.description}
						</Typography>
						<DefaultButton
							sx={{
								mt: 10,
							}}
							onClick={() => {
								closePage();
							}}>
							{localization.pages.thankYou.cta}
						</DefaultButton>
					</Stack>
				</Grid>
			</Grid>
		</>
	);
}