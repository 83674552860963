import { Stack } from "@mui/material";
import DefaultButton from "../../../components/DefaultButton";
import useLocalization from "../../../hooks/useLocalization";

export default function CTASection({ onGetInTouch, onLearnMore }: { onGetInTouch: () => void, onLearnMore?: () => void }) {
	const localization = useLocalization();

	return (
		<Stack direction={{ xs: "column", lg: "row" }} alignItems="center" justifyContent="center" spacing={2} px={2}>
			<DefaultButton
				onClick={() => {
					onGetInTouch?.();
				}}>
				{localization.pages.mobileLanding.cta.getInTouch}
			</DefaultButton>
			{/* <DefaultButton
				sx={{
					color: "#fff",
					backgroundColor: "transparent",
					'&:hover': {
						backgroundColor: '#f2f2f210'
					},
				}}
				onClick={() => {
					onLearnMore?.();
				}}>
				{localization.pages.mobileLanding.cta.learnMore}
			</DefaultButton> */}
		</Stack>
	);
}