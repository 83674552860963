import { Box, Grid, Stack, Typography } from "@mui/material";
import GradientTypography from "../../../components/GradientTypography";
import { Fade } from "@gioxxy/react-awesome-reveal";
import useLocalization from "../../../hooks/useLocalization";

export default function SkillsSection() {
	const localization = useLocalization();

	return (
		<Stack alignItems="center" spacing={{ xs: 2, lg: 5 }}>
			<Stack alignItems="center" px={{ xs: 2, lg: 25 }} spacing={2}>
				<GradientTypography
					sx={{
						fontWeight: 900,
						fontSize: { xs: 40, md: 50 },
						textAlign: "center"
					}}>
					{localization.pages.mobileLanding.skills.title}
				</GradientTypography>
				<Typography
					textAlign="center"
					fontSize={16}>
					{localization.pages.mobileLanding.skills.description}
				</Typography>
			</Stack>
			<Grid
				container
				rowSpacing={{ xs: 5, lg: 0 }}
				justifyContent="center"
				alignItems="center"
				px={{ xs: 2, md: 10, lg: 25 }}>
				{localization.pages.mobileLanding.skills.items.map(({ title, skills }, index) => {
					const itemsLength = localization.pages.mobileLanding.skills.items.length;
					return (
						<Grid
							item
							key={index}
							xs={12}
							lg={12 / itemsLength}
							pr={{ sx: 0, md: 5, lg: 10 }}>
							<Fade direction="up" delay={index * 10} triggerOnce>
								<Stack direction="row" spacing={2} alignItems="top">
									<GradientTypography
										sx={{
											fontWeight: 900,
											fontSize: { xs: 35, md: 50 },
											height: "fit-content"
										}}>
										{index + 1}
									</GradientTypography>
									<Stack width="100%">
										<Typography
											sx={{
												fontWeight: 900,
												fontSize: { xs: 25, md: 30 },
												lineHeight: { xs: 1, md: 1.2 }
											}}>
											{title}
										</Typography>
										<Grid container spacing={1} justifyContent="center" alignItems="center" pt={2}>
											{skills.map((skill, index) => {
												return (
													<Grid item xs={6} lg={12} key={index}>
														<Fade direction="up" delay={index * 10} key={index} triggerOnce>
															<Box sx={{
																background: "-webkit-linear-gradient(45deg, #131313 30%, #0b0b0b 90%)",
																width: "fill-available",
																borderRadius: "10px",
																py: { xs: 2, lg: 2 },
																px: { xs: 2, lg: 3 }
															}}>
																<Typography fontWeight={800} fontSize={{ xs: 12, lg: 15 }}>{skill}</Typography>
															</Box>
														</Fade>
													</Grid>
												);
											})}
										</Grid>
									</Stack>
								</Stack>

							</Fade>
						</Grid>
					);
				})}
			</Grid>
		</Stack>
	);
} 