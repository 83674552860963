export interface LanguageLocalization { };

export enum Language {
	en = "en",
	fr = "fr",
	es = "es",
	de = "de",
	it = "it",
	pt = "pt",
	ru = "ru",
	ja = "ja",
	ko = "ko",
	zh = "zh",
	zh_tw = "zh_tw"
}

type LanguageLocalizations<T> = {
	en: T;
	fr?: T;
	es?: T;
	de?: T;
	it?: T;
	pt?: T;
	ru?: T;
	ja?: T;
	ko?: T;
	zh?: T;
	zh_tw?: T;
}

export class Localization<T> {
	languages: LanguageLocalizations<T>;
	// current: T;

	private _currentLanguage: Language;
	set currentLanguage(language: Language | string) {
		if (typeof language === "string") {
			this._currentLanguage = (language as Language) ?? Language.en;
		} else {
			this._currentLanguage = language;
		}
	};

	get current(): T {
		return this.languages[this._currentLanguage] ?? this.languages.en;
	}

	constructor(language: Language, { en, fr, es, de, it, pt, ru, ja, ko, zh, zh_tw }: LanguageLocalizations<T>) {
		this.languages = { en, fr, es, de, it, pt, ru, ja, ko, zh, zh_tw }
		this._currentLanguage = language;
	}

	setLanguage(language: Language) {
		this._currentLanguage = language;
	}
}

export function getLocalizedPath(path: string, language?: Language) {
	if(language === Language.en) {
		return path;
	}
	return `/${language}${path}`;
}