import { TextField, debounce } from "@mui/material";
import TextFieldController from "../models/ValidableTextFieldController";
import { useState } from "react";
import { useInit } from "../hooks/useInit";

export default function ValidableTextField({ controller, ...props }: { controller: TextFieldController, [key: string]: any }) {

	const [error, setError] = useState<string | undefined>();
	const [value, setValue] = useState<string>("");

	useInit(() => {
		controller.validate = (value) => {
			const res = controller.validation(value);
			setError(controller.error);
			return res;
		}
		controller.clear = () => {
			controller.clearError();
			controller.value = undefined;
			setError(controller.error);
			setValue(controller.value || "");
		}
	});

	const validate = debounce(() => {
		controller.validate(controller.value)
	}, 1000);

	const onChange = (value: string) => {
		controller.value = value;
		setValue(controller.value);
		validate();
	}

	return (
		<TextField
			value={value}
			error={!!error}
			helperText={error}
			onChange={(event) => {
				onChange(event.target.value);
			}}
			{...props}
		/>
	);
}