import { Button, Grid, Stack, Typography } from "@mui/material";
import GradientTypography from "./GradientTypography";
import { ReactComponent as GroGrapesLogo } from "../assets/images/grograpes-logo.svg";
import GroGrapesViticulturist from "../assets/images/grograpes-viticulturist.png";
import WCAPESLogo from "../assets/images/wcapes-logo.png";
import WCAPESAmbassadors from "../assets/images/wcapes-ambassadors.png";
import AowayLogo from "../assets/images/aoway-logo.png";
import AowayNeurons from "../assets/images/aoway-neurons.png";
import { Fade } from "@gioxxy/react-awesome-reveal";

export default function ProjectsSection() {

	function openGroGrapes() {
	}

	function openWCAPES() {
	}

	function openAoway() {
	}

	return (
		<Fade direction="up" triggerOnce>
			<Stack alignItems="center" spacing={{ xs: 5, lg: 10 }}>
				<Stack alignItems="center">
					<GradientTypography
						sx={{
							fontWeight: 900,
							fontSize: { xs: 30, md: 50 }
						}}
					>
						We bring businesses
					</GradientTypography>
					<Typography
						height={1}
						fontWeight={900}
						fontSize={{ xs: 25, md: 45 }}>
						into the Web3 world
					</Typography>
				</Stack>
				<Grid container
					rowSpacing={{ xs: 3, md: 0 }}
					columnSpacing={{ xs: 0, md: 5 }}
					px={{ xs: 5, lg: 20 }}
					alignItems="stretch">
					<Grid item xs={12} sm={4}>
						<Fade direction="right" delay={100}>
							<Button
								sx={{
									height: "100%",
									width: "100%",
									borderRadius: "10px",
									textTransform: "none"
								}}
								onClick={() => {
									openGroGrapes();
								}}>
								<Stack
									direction="column"
									alignItems="center"
									spacing={2}
									width="100%">
									<Stack
										direction="row"
										alignItems="end"
										justifyContent="end"
										spacing={-2}
										sx={{
											width: "100%",
											backgroundColor: "#131313",
											borderRadius: "10px",
											aspectRatio: "16/9",
											overflow: "hidden"
										}}>
										<GroGrapesLogo style={{ height: "90%", width: "auto", margin: "20px" }} />
										<img src={GroGrapesViticulturist} alt="GroGrapes Viticulturist" height="90%" />
									</Stack>
									<Typography
										fontSize={20}
										fontWeight={800}>
										GroGrapes
									</Typography>
								</Stack>
							</Button>
						</Fade>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Fade direction="right" delay={200}>
							<Button
								sx={{
									height: "100%",
									width: "100%",
									borderRadius: "10px",
									textTransform: "none"
								}}
								onClick={() => {
									openWCAPES();
								}}>
								<Stack
									direction="column"
									alignItems="center"
									spacing={2}
									width="100%">
									<Stack
										direction="row"
										alignItems="end"
										justifyContent="end"
										spacing="-25%"
										sx={{
											width: "100%",
											backgroundColor: "#EAC44B",
											borderRadius: "10px",
											overflow: "hidden",
											aspectRatio: "16/9"
										}}>
										<img src={WCAPESLogo} alt="WCAPES Logo" height="100%" />
										<img src={WCAPESAmbassadors} alt="WCAPES Ambassadors" height="90%" />
									</Stack>
									<Typography
										fontSize={20}
										fontWeight={800}>
										World Champion Apes
									</Typography>
								</Stack>
							</Button>
						</Fade>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Fade direction="right" delay={300}>
							<Button
								sx={{
									height: "100%",
									width: "100%",
									borderRadius: "10px",
									textTransform: "none"
								}}
								onClick={() => {
									openAoway();
								}}>
								<Stack
									direction="column"
									alignItems="center"
									spacing={2}
									width="100%">
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="start"
										spacing="-10px"
										sx={{
											width: "100%",
											backgroundColor: "#131313",
											borderRadius: "10px",
											backgroundImage: `url(${AowayNeurons})`,
											backgroundSize: "cover",
											aspectRatio: "16/9",
										}}>
										<img src={AowayLogo} alt="WCAPES Logo" width="10%" style={{ marginLeft: "20px" }} />
									</Stack>
									<Typography
										fontSize={20}
										fontWeight={800}>
										Aoway
									</Typography>
								</Stack>
							</Button>
						</Fade>
					</Grid>
				</Grid>
			</Stack>
		</Fade>
	);
}