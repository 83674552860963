import { Button, Stack } from "@mui/material";
import useLocalization from "../../../hooks/useLocalization";
import Logo from "../../../components/Logo";

export default function Navbar({ onScrollToGetInTouch, ...props }: { onScrollToGetInTouch?: () => void }) {
	const localization = useLocalization();

	const buttonStyle = {
		height: "100%",
		px: 5,
		textTransform: "none",
		fontWeight: 900,
		fontSize: 17
	}

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			px={{ xs: 2, md: 10 }}
			pt={2}
			{...props}>
			<Logo
				sx={{
					width: { xs: 50, md: 100 },
					height: { xs: 45, md: 90 }
				}} />
			<Stack direction="row" spacing={2} display={{xs: "none", lg: "block"}}>
				<Button sx={buttonStyle} onClick={() => { onScrollToGetInTouch?.() }}>
					{localization.components.navbar.getInTouch}
				</Button>
			</Stack>
		</Stack>
	);
}