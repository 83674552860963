import { Box, Button, Stack, Typography } from "@mui/material";
import { ReactComponent as SublocksLogoMark } from "../assets/images/logomark.svg";
import GradientTypography from "./GradientTypography";
import { Fade } from "@gioxxy/react-awesome-reveal";

export default function Footer() {
	return (
		<Fade direction="up" triggerOnce>
			<Stack
				justifyContent="center"
				alignItems="center"
				spacing={1}>
				<Box
					sx={{
						width: { xs: 100, md: 150 }
					}}>
					<SublocksLogoMark width="100%" height="100%" />
				</Box>
				<GradientTypography
					sx={{
						fontWeight: 800,
						fontSize: { xs: 22, md: 32 }
					}}>
					Sublocks Srl
				</GradientTypography>
				<Typography
					textAlign="center">
					Via Ala di Stura 67, 10148 Torino
					<br />
					P.IVA 12849290015
				</Typography>
				<Button
					sx={{
						textTransform: "none"
					}}
					onClick={() => {
						window.open("mailto:info@sublocks.com");
					}}>
					<GradientTypography
						sx={{
							fontWeight: 800,
							fontSize: { xs: 12, md: 15 }
						}}>
						info@sublocks.com
					</GradientTypography>
				</Button>
			</Stack>
		</Fade>
	);
}