import { Button } from "@mui/material";
import { ReactComponent as SublocksLogo } from "../assets/images/logo.svg";

export default function Logo({ onClick, ...props }: { onClick?: () => void, [key: string]: any }) {
	return (
		<Button
			onClick={() => { onClick?.() }}
			{...props}>
			<SublocksLogo />
		</Button>
	);
}